<template>
  <div class="bb-onboarding">
    <no-ssr>
      <div class="bb-container portal--fixed">
        <portal-target name="alerts_onboarding"></portal-target>
      </div>
    </no-ssr>

    <alert v-if="showAlert" portal="alerts_onboarding" />

    <div class="bg-cercles bb-onboarding__container show-back">
      <ResponsiveMobile :targetClass="['bb-onboarding-back']">
        <div class="back" v-if="!isNewUser">
          <div class="back__back">
            <router-link :to="{ name: 'owner-list' }">
              <div class="back__name">
                <img
                  src="../../assets/img/ico/ico-arrow-back.svg"
                  :alt="$t('generic.goback')"
                  class="back__arrow"
                />
              </div>
            </router-link>
          </div>
        </div>
      </ResponsiveMobile>

      <div class="bb-onboarding__wrapper">
        <div class="bb-onboarding__header bb-onboarding__header--wrap">
          <div class="bb-onboarding__title">{{ $t("onboarding.step0Title") }}</div>
          <div class="bb-onboarding__description"></div>

          <section class="hbbm-verticales-onboarding">
            <div class="hbbm-verticales-onboarding__container">
              <div class="hbbm-verticales-onboarding__items">
                <div
                  class="hbbm-verticales-onboarding__item"
                  v-for="item in verticales"
                  :key="item.name"
                >
                  <div
                    class="hbbm-verticales-onboarding__content"
                    @click="item.onClick ? item.onClick() : doNothing()"
                  >
                    <div class="hbbm-verticales-onboarding__name">
                      {{ item.name }}
                    </div>
                    <picture class="hbbm-verticales-onboarding__image">
                      <img :src="item.cover" :alt="item.name" />
                    </picture>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>

    <ModalDialog
      :show="showListTypeModal"
      @close="showListTypeModal = false"
      target-class="modal__card--mini"
    >
      <div class="modal-article">
        <h3 class="modal-article__header">
          {{ $t("onboarding.listModalHeader") }}
        </h3>

        <div class="modal-article__content">
          <InputSelect
            :error="otherListTypeError"
            :options="otherListTypes"
            :placeholder="$t('onboarding.listType')"
            :required="true"
            @input="checkModal()"
            label=""
            style="margin: 1em auto;"
            v-model="selectedListType"
            :errorLabel="$t('generic.field_is_required')"
          />

          <button
            @click="goToSelectedOnboarding()"
            class="button button--primary button--block"
          >
            {{ $t("generic.next") }}
          </button>
        </div>
      </div>
    </ModalDialog>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NoSSR from "vue-no-ssr";

import ModalDialog from "../../components/ui/ModalDialog";
import Alert from "../../components/ui/Alert";
import InputSelect from "../../components/forms/InputSelect";
import ResponsiveMobile from "../../components/responsive/ResponsiveMobile";

import imgWishlist from "../../assets/img/covers/cover-hbbm-wishlist.jpg";


export default {
  name: "OnboardingStep0",
  components: {
    ModalDialog,
    InputSelect,
    alert: Alert,
    "no-ssr": NoSSR,
    ResponsiveMobile,
  },
  data() {
    return {
      showListTypeModal: false,
      selectedListType: "",
      selectedListTypeDirty: false,
      key: "onboarding",
      otherListTypeError: false,

      verticales: [
        {
          name: this.$t("onboarding.listTypeWishlist"),
          cover: imgWishlist,
          onClick: () => { return this.goTo("wishlist"); },
        },
        {
          name: this.$t("onboarding.listTypeBabyRegistry"),
          cover: "cover-hbbm-mujer-cuna.jpg",
          onClick: () => { return this.goTo("baby-registry"); },
        },
        {
          name: this.$t("onboarding.listTypeWedding"),
          cover: "cover-hbbm-wedding.jpg",
          onClick: () => { return this.goTo("wedding"); },
        },
        {
          name: this.$t("onboarding.listTypeOtherType"),
          cover: "cover-hbbm-globos.jpg",
          onClick: () => {
            return this.openModal();
          },
        },
      ],
      otherListTypes: [
        { value: "birthday", label: this.$t("onboarding.listTypeBirthday") },
        { value: "christmas", label: this.$t("onboarding.listTypeChristmas") },
        { value: "babyshower", label: this.$t("onboarding.listTypeBabyShower") },
        { value: "home", label: this.$t("onboarding.listTypeHome") },
        { value: "other", label: this.$t("onboarding.listTypeOther") },
      ],
    };
  },
  computed: {
    ...mapGetters({
      showAlert: "showAlert",
    }),
    isNewUser() {
      return (
        typeof this.$route.meta?.isNewUser === "undefined" ||
        this.$route.meta?.isNewUser === true
      );
    },
  },
  methods: {
    doNothing() {
      // Remove when no longer needed.
    },
    openModal() {
      this.showListTypeModal = true;
    },
    async goTo(listType) {
      try {
        if (this.isNewUser) {
          await this.$store.dispatch("setListType", listType);

          this.$router.push(`/onboarding/${listType}`);
        } else {
          this.$router.push(`/new-list/${listType}`);
        }
      } catch (err) {
        console.error(err);
        this.$alert.error(this.$t("generic.errorNoSave"));
        this.showListTypeModal = false;
      }
    },
    checkModal() {
      this.otherListTypeError = false;
      if (this.selectedListTypeDirty) {
        this.otherListTypeError = !(this.selectedListType || "").trim();
      }

      return !this.otherListTypeError;
    },
    goToSelectedOnboarding() {
      this.selectedListTypeDirty = true;
      if (!this.checkModal()) {
        return;
      }

      return this.goTo(this.selectedListType);
    },
  },
};
</script>

<style></style>
