<template>
  <div class="ml-selector">
    <div v-if="loading">
      <Loading />
    </div>
    <div class="ml-selector__top" v-else-if="visibleLists.length > 0">
      <div class="ml-selector__title">{{ $t("mylist.selectList") }}</div>
      <div class="ml-selector__items">
        <div
          class="ml-selector__item"
          v-for="list in visibleLists"
          @click="changeList(list.id)"
          :key="list.id"
        >
          <article class="ml-selector-list">
            <!-- hiding list image for the moment -->
            <!-- <div class="ml-selector-list__avatar">
              <img :src="list.imageUrl" alt="" />
            </div> -->
            <div class="ml-selector-list__content">
              <div class="ml-selector-list__name">{{ list.title }}</div>
              <div class="ml-selector-list__count">
                {{ $t("mylist.listItems", { n: list.totalProducts }) }}
              </div>
            </div>
          </article>
        </div>
      </div>
      <div class="ml-selector__actions" v-if="!isCollaborator">
        <router-link to="/listas">
          {{ $t("mylist.viewLists", { n: lists.length }) }}
          <i class="uil uil-angle-right"></i>
        </router-link>
      </div>
    </div>

    <div class="ml-selector__bottom" v-if="!isCollaborator">
      <!-- <div class="ml-selector__title">Crear nueva lista</div> -->
      <div class="ml-new-list">
        <!-- <div class="ml-new-list__item" @click="createList('baby-registry')">
          <i class="uil uil-plus-circle"></i>
          {{ $t('onboarding.listTypeBabyRegistry') }}
        </div>
        <div class="ml-new-list__item" @click="createList('babyshower')">
          <i class="uil uil-plus-circle"></i>
          {{ $t('onboarding.listTypeBabyShower') }}
        </div> -->
        <div class="ml-new-list__item" @click="createList()">
          <i class="uil uil-plus-circle"></i>
           {{ $t("mylist.createList") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Loading from "../../../components/Loading";

export default {
  name: "MultilistSelector",
  components: {
    Loading,
  },
  props: {
    lists: {
      type: Array,
      default: [],
    },
    defaultListId: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isCollaborator: {
      type: Boolean,
      default: false, // TODO: Change for `required: true` after removing
                      // the component `ListHeaderNoMultiList`.
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
    visibleLists() {
      return this.lists
        .filter((l) => l.id !== this.defaultListId)
        .sort((a, b) => b.id - a.id)
        .slice(0, 3);
    },
  },
  methods: {
    async changeList(listId) {
      await this.$store.dispatch("updateUserDefaultListId", listId);
      await this.$store.dispatch("getOwnerList");

      this.$clearOwnerProductFilters();

      this.$emit("close");
    },
    createList(listType) {
      if (listType) {
        return this.$router.push(`/new-list/${encodeURIComponent(listType)}`);
      }

      return this.$router.push({
        name: "NewList",
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
