import {
    ENABLED_IN as CONTRIBUTIONS_ENABLED_IN,
    FEES_SUPPORTED_BY,
} from "../constants/contributions-configuration";

// DEPRECATED: Use the `feature*Available` getters instead.
import { INTERNATIONAL_COUNTRIES } from "../constants/international";

import { BY_COUNTRY_CODE } from "../constants/countries";

export const isServer = state => !(typeof window !== "undefined" && window.document);
export const cookies = state => state.cookies;
export const user = state => state.user;
export const childBirthday = state => state.user.childBirthday;
export const childName = state => state.user.childName;
export const isAuthenticated = state => state.isAuthenticated;
export const auth_token = state => state.auth_token;
export const users = state => state.users;
export const categories = state => state.categories;
export const categoriesPrefetched = state => state.categoriesPrefetched;
export const collections = state => state.collections;
export const collectionsPrefetched = state => state.collectionsPrefetched;
export const blogTags = state => state.blogTags;
export const blogTagsPrefetched = state => state.blogTagsPrefetched;
export const myList = state => state.myList;
export const listUpdated = state => state.listUpdated;
export const ownerList = state => state.ownerList;
export const ownerListProduct = state => state.ownerListProduct;
export const products = state => state.products;
export const productsConstructor = state => state.productsConstructor;
export const loadingProducts = state => state.loadingProducts;
export const productsPrefetched = state => state.productsPrefetched;
export const productFilters = state => state.productFilters;
export const guestProductFilters = state => state.guestProductFilters;
export const ownerProductFilters = state => state.ownerProductFilters;
export const waitingFilters = state => state.waitingFilters;
export const breadcrumb = state => state.breadcrumb;
export const product = state => state.product;
export const currencies = state => state.currencies;
export const currenciesPrefetched = state => state.currenciesPrefetched;
export const listActive = state => state.listActive;
export const browserLanguage = state => state.browserLanguage || "";
export const browserLanguageWithoutCountry = state => state.browserLanguage.split("-")[0];
export const isButtonUser = state => state.isButtonUser;
export const promotions = state => state.promotions;

function featureIsEnabled(state, feature) {
    const country = countryCode(state);
    const continent = continentCode(state);
    console.log("featureIsEnabled", country, continent, feature)

    if (country === "ES") {
        return true;
    }

    if (feature === "webMap") {
        return true;
    }

    if (feature === "plan" && (country == "FR" || country == "IT" || country == "CH" || country == "BE" || country == "LU")) {
        return false;
    }

    if (continent === "SA") {
        const disabled = new Set([
            "catalog",
            "ecommerce",
            "contributions"
        ]);
        return !disabled.has(feature);
    }

    if (continent === "EU") {
        const disabled = new Set([
            "blog",
            "catalog",
            "ecommerce",
        ]);

        return !disabled.has(feature);
    }

    return true;
}

// Flags for feature-wide availability.
export const featureBlogAvailable = state => featureIsEnabled(state, "blog");
export const featureCatalogAvailable = state => featureIsEnabled(state, "catalog");
export const featureContributionsAvailable = state => featureIsEnabled(state, "contributions");
export const featureEcommerceAvailable = state => featureIsEnabled(state, "ecommerce");
export const featureWebMapAvailable = state => featureIsEnabled(state, "webMap");

// Only affects whether this user can see things that mention plans. It doesn't
// change the fact that the user still has some plan, and the permissions from
// that plan still apply.
export const featurePlanAvailable = state => featureIsEnabled(state, "plan");

const getContributionsFlag = function (user) {
    try
    {
        var res = true
        if(user === undefined) return res
        if(user === null) return res
        if(user.featureFlags === undefined) return res
        if(user.featureFlags === null) return res
        const flag = user.featureFlags.find(f => f.code === 'contributions')
        if (flag === undefined) return res
        if (flag === null) return res
        res = flag.isEnabled
        return res
    }
    catch (e) {
        console.log(e)
        return false
    }
}

export const contributionsFlag = (state) => { return getContributionsFlag(state.user) }

export const selectedOptions = state => state.selectedOptions;

const getSelectedVariant = function (product, selectedOptions) {
    const variants = Array.isArray(product.variants) ? product.variants : [];
    return variants?.find((variant) => {
        let match = 0
        variant.optionValues.forEach((optionValue) => {
            if (Array.isArray(selectedOptions) && selectedOptions?.length > 0) {
                const selectedOption = selectedOptions?.find(selectedOption => selectedOption.name === optionValue.optionName)
                if (selectedOption?.value === optionValue.optionValue) {
                    match++;
                }
            }
        })
        if (match === variant.optionValues.length) {
            return variant
        }
        return null;
    });
}

export const selectedVariant = (state) => {
    return getSelectedVariant(state.product, state.selectedOptions);
}

export const selectedVariantOwnerListProduct = (state) => {
    return getSelectedVariant(state.ownerListProduct, state.selectedOptions);
}

export const selectedQuantity = state => state.selectedQuantity;

export const selectedCategory = state => state.selectedCategory;
export const orderBy = state => state.orderBy;
export const step = state => state.step;
export const steps = state => state.steps;
export const savedProducts = state => state.savedProducts;
export const searchState = state => state.searchState;
export const addToListVisibility = state => state.addToListVisibility;
export const ctaFixedVisibility = state => state.ctaFixedVisibility;
export const ctaFixedVisibility2 = state => state.ctaFixedVisibility2;
export const brands = state => state.brands;
export const tags = state => state.tags;
export const retailers = state => state.retailers;
export const post = state => state.post;
export const posts = state => state.posts;
export const featuredPost = state => state.featuredPost;
export const featured2Posts = state => state.featured2Posts;
export const productPosts = state => state.productPosts;
export const maternityPosts = state => state.maternityPosts;
export const exampleListsPosts = state => state.exampleListsPosts;
export const categoryPosts = state => state.categoryPosts;
export const tag = state => state.tag;
export const invitation = state => state.invitation;
export const toggleCategory = state => state.toggleCategory;
export const collection = state => state.collection;
export const collectionProducts = state => state.collectionProducts;
export const collectionProductsPrefetched = state => state.collectionProductsPrefetched;

export const showAlert = state => state.showAlert;
export const showAlertKey = state => state.showAlertKey;
export const alertMessage = state => state.alertMessage;
export const alertType = state => state.alertType;
export const showRetry = state => state.showRetry;
export const alertRetrying = state => state.alertRetrying;
export const parserError = state => state.parserError;
export const parserErrorUrl = state => state.parserErrorUrl;
export const listConstructor = state => state.listConstructor;
export const limit = state => state.limit;
export const temporalLimit = state => state.temporalLimit;
export const openSections = state => state.openSections;
export const cart = state => state.cart;
export const toggleCart = state => state.toggleCart;
export const toggleLanguageSelector = state => state.toggleLanguageSelector;

export const getFirstStepData = state => state.firstStepData;
export const getSecondStepData = state => state.secondStepData;

export const confirmationMessage = state => state.confirmationMessage;
export const landbotVisible = state => state.landbotVisible;
export const reloadLandbot = state => state.reloadLandbot;
export const constructorQuery = state => state.constructorQuery;
export const balance = state => state.balance;

export const loggedInUserHasPiggyBankEnabled = state => (
    (state.user &&
    typeof state.user.stripeAccountId === "string" &&
    state.user.stripeAccountId.trim() !== "")
    ||
    (state.ownerList &&
    typeof state.ownerList.stripeAccountId === "string" &&
    state.ownerList.stripeAccountId.trim() !== "")
);

export const myListHasPiggyBankEnabled = state => (
    (state.myList &&
    typeof state.myList.stripeAccountId === "string" &&
    state.myList.stripeAccountId.trim() !== "")
);

export const loggedInUserHasLegacyPaymentMethods = state => (
    state.ownerList &&
    Array.isArray(state.ownerList.funds) &&
    state.ownerList.funds.some((fund) => (
        fund.fundPaymentTypes.length > 0
    ))
);

export const loggedInUserIsPremium = state => (
    state.user &&
    state.user.userType === "Premium"
);

export const loggedInUserCurrency = state => ({
    symbol: user(state).currencySymbol,
});

export const myListCurrency = state => ({
    symbol: myList(state).currencySymbol,
});

export const shouldDisplayAds = state => (
    !state.myList ||
    state.myList.userRole !== "Premium"
);

export const userIsVisitor = state => {
    if (state.user) return false
    return true
};

function hasLocalStorage() {
    return typeof window !== "undefined" && typeof window.localStorage !== "undefined";
}

export const guestCountry = state => (
    state.guestCountry || ""
)

export const userCountry = state => (
    state.user?.locationCountry || ""
)

export const countryCode = state => (
    userCountry(state) || guestCountry(state) || ""
);

export const continentCode = state => {
    const cc = countryCode(state);

    const countryData = BY_COUNTRY_CODE[cc];

    return countryData?.continent || "";
};

// DEPRECATED: Use the `feature*Available` getters instead.
export const isInternational = state => {
    const cc = countryCode(state);

    return cc && INTERNATIONAL_COUNTRIES.has(cc);
};

function getDefaultContributionsConfiguration() {
    console.warn("Contributions configuration not found. Using defaults as fallback.");

    return {
        enabledIn: CONTRIBUTIONS_ENABLED_IN.all,
        feesSupportedBy: FEES_SUPPORTED_BY.contributor,
        activationThresholdAmount: 0,
    };
}

export const contributionsConfiguration = state => (
    state.myList?.contributionsConfiguration
    || state.user?.contributionsConfiguration
    || getDefaultContributionsConfiguration()
);

export const contributionsConfigurationEnabledIn = state => (
    contributionsConfiguration(state)?.enabledIn
);

export const contributionsConfigurationFeesSupportedBy = state => (
    contributionsConfiguration(state)?.feesSupportedBy
);

export const contributionsConfigurationActivationThresholdAmount = state => (
    contributionsConfiguration(state)?.activationThresholdAmount
);

export const hidePiggyBank = state => (
    !featureContributionsAvailable(state)
);

export const isApp = state => {
    var res = state.cookies.sourceApp === 'true' ? true : false
    return res
}

export const externalAccount = state => (
    state.externalAccount
);

export const preferredLanguage = state => (
    (countryCode(state) === "FR" || countryCode(state) === "BE" || countryCode(state) === "LU" || countryCode(state) === "CH")
    ? "fr-FR" : continentCode(state) === "SA" ? "es-MX" : countryCode(state) === "IT"? "it-IT" : "es"
);

export const guestListHasHelloBBProducts = state => (
    Array.isArray(myList(state)?.products) &&
    myList(state).products.length > 0 &&
    myList(state).products.some((product) => product.retailerName.toLowerCase() === "hellobb")
);

// A "preview list" is a list where all interactions are disabled.
export const isPreviewList = state => {
    const previewListId = parseInt(process.env.PREVIEW_LIST_ID, 10);
    const previewListIdIT = parseInt(process.env.PREVIEW_LIST_IT_ID, 10);
    const previewListIdES = parseInt(process.env.PREVIEW_LIST_ES_ID, 10);
    const listId = myList(state)?.id;

    return previewListId > 0 && (previewListId === listId || previewListIdIT === listId || previewListIdES === listId);
};

// Same as a "preview list", but without any banner indicating that it's a
// preview list.
export const isLockedList = state => {
    const listId = myList(state)?.id;

    const lockedIds = new Set(
        (process.env.LOCKED_LIST_IDS || "")
            .split(",")
            .map(id => id.trim())
            .filter(id => id !== "")
            .map(id => Number.parseInt(id, 10))
            .filter(id => !Number.isNaN(id) && id > 0)
    );

    return lockedIds.has(listId);
};

export const myListLanguage = state => (
    state.cookies?.language || state.myList?.language
);

export const ownerListType = state => (
    state.ownerList?.type
    || (state.user?.lists || []).find(list => list.id === state.ownerList.id)?.type  // TODO: Check if this is still necessary.
    || ""
);

export const myListType = state => (
    state.myList?.type || ""
);
