<template>
  <div>
    <div class="pad-t-2" v-if="show">
      <div class="bb-container bb-container--lista">
        <div class="list-section">
          <div class="list-section__header">
            <div
              class="bb-accordion"
              @click="clickedToggleFund"
              :class="toggleFund ? 'bb-accordionn--open' : ''"
            >
              <div class="bb-accordion__title">
                <h3 class="header-3">
                  {{ $t("mylist.fundsSectionTitle") }} <small> ({{ totalFunds }}) </small>
                </h3>
                <span
                  class="bb-accordion__tooltip bb-accordion__tooltip--warning"
                  v-if="shouldShowFundsWarningTooltip && !toggleFund"
                >
                  <i class="uil uil-exclamation-triangle"> </i>
                </span>
              </div>
              <span class="bb-accordion__right">
                <img
                  src="../../../assets/img/ico/ico-chevron-up.svg"
                  loading="lazy"
                  alt=""
                  width="16px"
                  :class="
                    toggleFund
                      ? 'bb-accordion__chevron bb-accordion__chevron--active'
                      : 'bb-accordion__chevron '
                  "
                />
              </span>
            </div>
          </div>

          <transition name="bbUp">
            <div class="list-section__content mar-b-3" v-if="toggleFund">
              <div class="cta-activa-hucha" v-if="shouldShowFundsWarningTooltip">
                <i class="uil uil-exclamation-triangle"></i>
                <div class="cta-activa-hucha__content">
                  {{ $t("mylist.fundsNotEnabled") }}
                  <span class="link link--bold link--underline" @click="goToHucha()">{{
                    $t("generic.enableNow")
                  }}</span>
                </div>
              </div>

              <div class="grid-fondos-cards">
                <FundCard
                  v-for="fund in funds"
                  :fund="fund"
                  :key="fund.id"
                  :list="list"
                  @removed="alert"
                ></FundCard>
                <OwnerListAlert v-if="showAlertLocal" />
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <template v-else>
      <div class="bb-container bb-container--lista">
        <div class="bbsk">
          <div class="bbsk__content">
            <div class="bbsk bbsk--h6" style="width: 100%"></div>
            <div class="bbsk bbsk--block" style="width: 100%; height: 200px"></div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { VTooltip } from "floating-vue";
import "floating-vue/dist/style.css";

export default {
  name: "Funds",
  components: {
    FundCard: () =>
      import(/* webpackPrefetch: true */ /* webpackChunkName: "FundCard" */ "./FundCard"),
    OwnerListAlert: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "OwnerListAlert" */ "./OwnerListAlert"
      ),
  },
  data() {
    return {
      toggleFund: true,
    };
  },
  directives: {
    tooltip: VTooltip,
  },
  computed: {
    ...mapGetters({
      list: "ownerList",
      showAlertKey: "showAlertKey",
      showAlert: "showAlert",
      loggedInUserHasPiggyBankEnabled: "loggedInUserHasPiggyBankEnabled",
      loggedInUserHasLegacyPaymentMethods: "loggedInUserHasLegacyPaymentMethods",
      isApp: "isApp",
    }),
    show() {
      if (this.funds) return this.funds.length > 0;
      return false;
    },
    totalFunds() {
      if (this.funds) return this.funds.length;
      return 0;
    },
    funds() {
      return this.list?.funds;
    },
    showAlertLocal() {
      return this.showAlertKey === this.key && this.showAlert;
    },
    key() {
      return "funds";
    },
    shouldShowFundsWarningTooltip() {
      return !this.loggedInUserHasPiggyBankEnabled && this.list.funds.length > 0;
    },
  },
  methods: {
    alert() {
      this.$alert.success(this.$t("mylist.fundModal.fundRemovedSuccess"), this.key);
    },
    clickedToggleFund(event) {
      this.toggleFund = !this.toggleFund;
    },
    goToHucha() {
      if (this.isApp) {
        location.href = process.env.APP_URL + "mi-hucha/crear?source=app";
      }
      else this.$router.push("/mi-hucha/crear");
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$store.dispatch("getOwnerList");
    });
  },
};
</script>
