<template>
  <div class="cta-caja cta-caja-sm cta-caja--grey cta-caja--qr" v-show="isVisible">
    <picture class="cta-caja-sm__cover">
      <img v-if="$i18n.locale === 'fr-FR'" src="../../../assets/img/covers/mockup-llista-fr-no-fondo.png" alt="" />
      <img v-else-if="$i18n.locale === 'it-IT'" src="../../../assets/img/covers/mockup-llista-it-no-fondo.png" alt="" />
      <img v-else src="../../../assets/img/covers/mockup_lista-es-no-fondo.png" alt="" />
    </picture>
    <div class="cta-caja-sm__main">
      <div class="cta-caja-sm__content">
        <h4 class="cta-caja-sm__title">{{ $t("constructor.appsBannerResp") }}</h4>
        <div class="cta-caja-sm__description-ml">
          {{ $t("constructor.appsBannerMsg1") }}
        </div>
        <div class="cta-caja-sm__description-ml">
          {{ $t("constructor.appsBannerMsg2") }}
        </div>
        <div class="cta-caja-sm__appstores">
          <CtaAppLinks lazy="eager"></CtaAppLinks>
        </div>
      </div>
      <div class="cta-caja-sm__actions">
        <img src="../../../assets/img/qr/qr-mobile-download.png" />
      </div>
      <span class="cta-caja-sm__close">
        <i class="uil uil-times" @click="close()"></i>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "BannerDownloadMobileAppsDesktop",
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    CtaAppLinks: () => import( /* webpackPrefetch: true */ /* webpackChunkName: "CtaAppLinks" */  '../../cta/CtaAppLinks'),
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
