<template>
  <div class="page-lists bg-cercles">
    <div class="bb-container bb-container--lista">
      <no-ssr>
        <div class="portal--fixed">
          <portal-target name="alerts_multilist"></portal-target>
        </div>
      </no-ssr>
      <alert v-if="showAlert" portal="alerts_multilist" />

      <ResponsiveMobile>
        <div class="back">
          <div class="back__back">
            <router-link :to="{ name: 'owner-list' }">
              <div class="back__name">
                <img
                  src="../../assets/img/ico/ico-arrow-back.svg"
                  :alt="$t('generic.goback')"
                  class="back__arrow"
                />
              </div>
            </router-link>
          </div>
        </div>
      </ResponsiveMobile>

      <div class="page-lists__header">
        <h1 class="page-lists__title">{{ $t("mylist.myLists") }}</h1>
      </div>
      <div class="page-lists__content">
        <div class="page-lists__items">
          <article class="page-lists-item" v-for="item in lists" :key="item.id">
            <!-- <div class="page-lists-item__cover">
              <img src="../../assets/img/covers/embarazada.jpg" alt="" />
            </div> -->
            <div class="page-lists-item__article">
              <time class="page-lists-item__from">{{ prettyData(item.createdAt) }}</time>
              <div class="page-lists-item__name">{{ item.title }}</div>
              <div class="page-lists-item__meta">
                <span>{{ $t("mylist.listItems", { n: item.totalProducts }) }}</span>
                <span>{{ $t("mylist.listGifts", { n: item.totalGiftedProducts }) }}</span>
              </div>
              <div class="page-lists-item__actions">
                <a href="#" @click.prevent="viewList(item.id)">{{ $t("mylist.viewList") }}</a>
                <a href="#" :class="canDelete ? [] : ['disabled']" @click.prevent="showDeleteModal(item.id)">{{ $t("mylist.deleteList") }}</a>
              </div>
            </div>
            <!-- <div class="page-lists-item__cover">
              <img src="../../assets/img/covers/embarazada.jpg" alt="" />
            </div>
            <div class="page-lists-item__article">
              <time class="page-lists-item__from">10 de Noviembre de 2023</time>
              <div class="page-lists-item__name">
                Lista de nacimiento de Aitana Pruñonosa Belliure
              </div>
              <div class="page-lists-item__meta">
                <span>10 objetos</span>
                <span>10 regalados</span>
              </div>
              <div class="page-lists-item__actions">
                <router-link to="/listas"> Ver lista </router-link>
                <router-link to="/listas"> Editar lista </router-link>
              </div>
            </div> -->
          </article>
        </div>
      </div>
    </div>

    <ModalDialog
      :show="showModal"
      @close="showModal = false"
      target-class="modal__card--mini"
    >
      <div class="card-wizard">
        <div class="card-wizard__title">
          <h3 class="header-3 w-70">
            {{ $t("mylist.deleteModal.title") }}
          </h3>
          <p>{{ $t("mylist.deleteModal.subtitle") }}</p>
        </div>
        <div class="card-wizard__actions card-wizard__actions--mt-0">
          <LoadingButton v-if="isDeleting" />
          <button
            v-else
            class="button button--primary button--block"
            @click="deleteList(listIdToDelete)"
          >
            {{ $t("generic.remove") }}
          </button>
          <button
            class="button button--secondary mar-t-fs-smaller button--block"
            @click="showModal = false"
          >
            {{ $t("generic.cancel") }}
          </button>
        </div>
      </div>
    </ModalDialog>
  </div>
</template>

<script>
import { list } from "postcss";
import { mapGetters } from "vuex";
import NoSSR from "vue-no-ssr";

import moment from "moment";
import moment_es from "moment/locale/es";

import CanGetOwnerList from "../../Mixins/CanGetOwnerList";
import Alert from "../../components/ui/Alert";

import ModalDialog from "../../components/ui/ModalDialog";
import LoadingButton from "../../components/LoadingButton";
import ResponsiveMobile from "../../components/responsive/ResponsiveMobile";

export default {
  name: "MultilistPageLists",
  components: {
    ModalDialog,
    LoadingButton,
    ResponsiveMobile,
    alert: Alert,
    "no-ssr": NoSSR,
  },
  mixins: [
    CanGetOwnerList,
  ],
  data() {
    return {
      isDeleting: false,
      showModal: false,
      lists: [],
      listIdToDelete: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
      showAlert: "showAlert",
    }),
    canDelete() {
      return this.lists && this.lists.length > 1;
    },
    defaultListId() {
      return this.list.id;
    },
  },
  methods: {
    prettyData(date) {
      return this.$t("mylist.created", {
        //dd/MM/yyyy
        humanDate: moment(date).format("DD/MM/yyyy"),
        //humanDate: moment(date).format(this.$t("components.calendar.dateFormatLong")),
      });
    },
    isDefaultList(listId) {
      return listId === this.defaultListId;
    },
    async setDefaultListDifferentTo(ignoreListId) {
      await this.updateLists();

      const otherLists = this.lists
        .filter(list => list.id !== ignoreListId)
        .sort((a, b) => b.id - a.id);

      const mostRecentDifferentListId = otherLists[0]?.id;

      await this.$store.dispatch("updateUserDefaultListId", mostRecentDifferentListId);
      await this.$store.dispatch("getOwnerList");
    },
    async showDeleteModal(listId) {
      if (!this.canDelete) {
        return;
      }

      this.listIdToDelete = listId;
      this.showModal = true;
    },
    async deleteList(listId) {
      if (!this.canDelete) {
        return;
      }

      this.isDeleting = true;
      try {
        // The default list is changed before deleting, just in case anything
        // goes wrong.
        if (this.isDefaultList(listId)) {
          await this.setDefaultListDifferentTo(listId);
        }

        await this.$store.dispatch("deleteList", listId);
        await this.updateLists();

        this.showModal = false;
        this.$alert.success(this.$t("mylist.removeListSuccess"));
      } finally {
        this.isDeleting = false;
      }
    },
    async viewList(listId) {
      await this.$store.dispatch("updateUserDefaultListId", listId);
      await this.$store.dispatch("getOwnerList");

      return this.$router.push({ name: "owner-list" });
    },
    async updateLists() {
      this.lists = await this.$store.dispatch("updateUserLists");
    },
  },
  async beforeMount() {
    await this.updateLists();
    console.log(this.lists);
  },
  mounted() {
    // TODO: Support other languages, somehow. Probably with a `switch` block
    // and calling `moment.locale` based on that.
    moment.locale("moment_es");
  },
};
</script>

<style lang="scss" scoped></style>
