<template>
    <div class="list-product-shops">
        <div
            class="list-product-shops__item"
            v-for="(productRetailer, index) in productRetailers"
            :key="index"
        >
            <div class="list-product-shops__shop">
            <img :src="$getLogoRetalier(productRetailer.name, productRetailer, product)" alt="" />
            <span v-text="productRetailer.name"></span>
            </div>
            <div class="list-product-shops__price">
            {{ $utils.formatMoney(productRetailer.price, currency) }}
            </div>
            <div class="list-product-shops__actions">
            <button
                class="button button--outline-dark button--smllr button--ico-end" @click.stop="openBuy(productRetailer)"
            >
                {{ $t("generic.buy") }} <i class="uil uil-external-link-alt"></i>
            </button>
            </div>
        </div>
        <div v-show="showMoreShops" class="list-product-shops__actions">
            <span
            @click.stop.prevent="toggleMoreShops = !toggleMoreShops"
            class="list-product-shops__more"
            ><span v-text="moreShopsSimbol"></span> {{ $t("mylist.stores") }}</span
            >
        </div>


        <!--  PRESENT MODAL-->
    <transition name="bbFade" @after-enter="showBuyContent = true">
      <div class="modal" v-if="showBuy" @click="showBuyContent = false">
        <transition name="bbUp" @after-leave="closeBuy()">
          <div v-show="showBuyContent" class="modal__card" @click.stop="">
            <div class="modal__dialog">
              <div class="modal__header">
                <div class="modal__back" v-show="buyStep > 1">
                  <img
                    @click="buyStep--"
                    src="../../../assets/img/ico/ico-arrow-back.svg"
                    alt="Atras"
                  />
                </div>
                <div class="modal__title">
                  <slot name="titulo"></slot>
                </div>
                <div class="modal__close">
                  <img
                    src="../../../assets/img/ico/ico-close.svg"
                    alt="Cerrar"
                    @click="showBuyContent = false"
                  />
                </div>
              </div>
              <div class="modal__content" ref="scroll_container">
                <FluxRegaloStep1
                  v-if="buyStep === 1"
                  :product="product"
                  :list="list"
                  :retailer="retailer"
                  @next="nextFirstStepBuy"
                ></FluxRegaloStep1>
                <FluxRegaloStep2
                  v-if="buyStep === 2"
                  :product="product"
                  :list="list"
                  :retailer="retailer"
                ></FluxRegaloStep2>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>


    </div>
</template>

<script>
import { mapGetters } from "vuex";
import FluxRegaloStep1 from "./FluxRegaloStep1";
import FluxRegaloStep2 from "./FluxRegaloStep2";

export default {
  name: "ProductRetailerLinks",
  components: {
    FluxRegaloStep1,
    FluxRegaloStep2,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      toggleMoreShops: false,
        showBuy: false,
        showBuyContent: false,
        buyStep: 1,
    };
  },
  computed: {
    ...mapGetters({
        myList: "myList",
        currency: "myListCurrency",
    }),
    list() {
        return this.myList;
    },
    productRetailers() {
      if (this.product.isFree) {
        return [
          {
            name: this.$utils.capitalize(this.product.retailerName),
            price: this.product.price,
          },
        ];
      }
      return (
        this.product.productRetailers?.slice(
          0,
          this.toggleMoreShops ? this.product.productRetailers?.length : 2
        ) || []
      );
    },
    showMoreShops() {
      return this.product.productRetailers?.length > 2;
    },
    moreShopsSimbol() {
      return this.toggleMoreShops ? "-" : "+";
    },
  },
  methods: {
    openBuy(pProductRetailer) {
        document.body.classList.add("modal-open");
      this.showBuy = true;
      this.retailer = pProductRetailer;
      window.mixpanel.identify(this.myList.userId);
      window.mixpanel.track("Comprar", {
        "Object name": this.product.name,
        "Retailer name": this.retailer?.name,
        "Selected Vendor": this.retailer?.name,
        "Object price": this.product.price,
        "List URL": "https://www.hellobb.net/guest/" + this.myList.id,
        "Buyer role": "Convidat",
        Src: "fitxa del regalador",
        "Sold by HelloBB": this.SoldByHelloBB,
        "Item Retailers": this.productRetailersName,
      });
    },
    nextFirstStepBuy() {
      window.mixpanel.identify(this.myList.userId);
      window.mixpanel.track("Redireccionar a retailer", {
        "Object name": this.product.name,
        "Selected Vendor": this.retailer?.name,
        "Object price": this.product.price,
        "List URL": "https://www.hellobb.net/guest/" + this.myList.id,
        "Buyer role": "Convidat",
        Src: "fitxa del regalador",
        "Sold by HelloBB": this.SoldByHelloBB,
        "Item Retailers": this.productRetailersName,
      });
      this.$emit("update");
      this.buyStep++;
      //this.resetScroll();
    },
    closeBuy() {
      this.showBuy = false;
      document.body.classList.remove("modal-open");
      this.buyStep = 1;
      this.$emit("close");
    },
  },
};
</script>
