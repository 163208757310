<template>
  <section class="alert-download-app">
    <div class="alert-download-app__content">
      <div class="alert-download-app__ico">
        <img src="../../../assets/img/ico/ico-color-mobile.svg" alt="" />
      </div>
      <div class="alert-download-app__label">
        <slot></slot>
      </div>
      <div class="alert-download-app__actions">
        <a class="link link--bold link--underline" style="color: white" @click="downloadApp()"
          >{{ $t("alerts.downloadAppButton") }}</a
        >
      </div>
    </div>
    <div class="alert-download-app__close">
      <i class="uil uil-times" @click="close()"></i>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "AlertAppDownload",
  computed: {
    ...mapGetters({
      isApp: "isApp",
    }),
  },
  methods: {
    close() {
      this.$emit("close");
    },
    downloadApp() {
      window.open("/mobile-app-download", "_blank");
    },
  },
};
</script>

<style lang="scss" scoped></style>
