<template>
  <div>
    <header class="hero-int">
      <div class="bb-container">
        <div class="hero-int__container">
          <div class="hero-int__left">
            <h1 class="hero-int__title">
              La lista de nacimiento para las familias de hoy
            </h1>
            <div class="hero-int__actions">
              <bbButton
                label="Empieza tu lista ahora"
                class="button--lg button--accent"
                @click="$router.push({ name: 'signup' })"
              />
              <a
                @click="$router.push({ name: 'guest', params: { id: exampleListId } })"
                class="link link--bold link--underline"
              >
                Ver una lista de ejemplo
              </a>
            </div>
          </div>
          <div class="hero-int__cover">
            <picture>
              <source
                srcset="../../assets/img/covers/cover-fr-hero@2x.png"
                media="(min-width: 1399px) and (min-device-pixel-ratio: 2)"
              />
              <img src="../../assets/img/covers/cover-fr-hero.png" alt="" />
            </picture>
          </div>
        </div>
      </div>
    </header>
    <section class="rate-app">
      <div class="bb-container">
        <div class="rate-app__container">
          <div class="rate-app__title">
            Más de 50.000 familias han hecho <br />su lista de nacimiento con HelloBB
          </div>
          <div class="rate-app__card">
            <div class="rate-app__item">
              <div class="rate-app__logo">
                <img src="../../assets/img/ico/ico-google-play.svg" alt="Google Play" />
              </div>
              <div class="rate-app__rate">4,8</div>
              <div class="rate-app__star">
                <img src="../../assets/img/ico/ico-star-fill.svg" alt="" />
              </div>
            </div>
            <hr />
            <div class="rate-app__item">
              <div class="rate-app__logo">
                <img src="../../assets/img/ico/ico-app-store.svg" alt="Apple App Store" />
              </div>
              <div class="rate-app__rate">4,8</div>
              <div class="rate-app__star">
                <img src="../../assets/img/ico/ico-star-fill.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="tiendas-list">
      <div class="tiendas-list__title">Elige cualquier producto de cualquier tienda</div>
      <div class="tiendas-list__items">
        <VueSlickCarousel v-bind="shopSlider">
          <div class="tiendas-list__item">
            <img loading="lazy" src="../../assets/img/logos/logo-af-corte-ingles.svg" />
          </div>
          <div class="tiendas-list__item">
            <img loading="lazy" src="../../assets/img/logos/logo-af-amazon.svg" />
          </div>
          <div class="tiendas-list__item">
            <img loading="lazy" src="../../assets/img/logos/logo-af-ikea.svg" />
          </div>
          <div class="tiendas-list__item">
            <img loading="lazy" src="../../assets/img/logos/logo-af-etsy.svg" />
          </div>
          <div class="tiendas-list__item">
            <img loading="lazy" src="../../assets/img/logos/logo-af-tutete.png" />
          </div>
          <div class="tiendas-list__item">
            <img loading="lazy" src="../../assets/img/logos/logo-af-maisons.svg" />
          </div>
          <div class="tiendas-list__item">
            <img loading="lazy" src="../../assets/img/logos/logo-af-mundobebes.png" />
          </div>
          <div class="tiendas-list__item">
            <img loading="lazy" src="../../assets/img/logos/logo-af-kiabi.svg" />
          </div>
        </VueSlickCarousel>
      </div>
    </section>
    <section class="hbb-features">
      <div class="bb-container">
        <div class="hbb-features__title">
          La lista de nacimiento online <span>reinventada</span>
        </div>
        <div class="hbb-features__items">
          <div class="hbb-features__item">
            <div class="hbb-features__ico">
              <img loading="lazy" src="../../assets/img/ico/ico-color-list-heart.svg" />
            </div>
            <div class="hbb-features__footer">
              <div class="hbb-features__h">La más bonita y personal</div>
              <div class="hbb-features__sh">
                Este momento único se merece algo a la altura.
              </div>
            </div>
          </div>
          <div class="hbb-features__item">
            <div class="hbb-features__ico">
              <img loading="lazy" src="../../assets/img/ico/ico-color-click-mobile.svg" />
            </div>
            <div class="hbb-features__footer">
              <div class="hbb-features__h">Fácil de usar para todos</div>
              <div class="hbb-features__sh">
                Zero problemas para ti y la gente con la que compartas tus deseos.
              </div>
            </div>
          </div>
          <div class="hbb-features__item">
            <div class="hbb-features__ico">
              <img loading="lazy" src="../../assets/img/ico/ico-color-gratis.svg" />
            </div>
            <div class="hbb-features__footer">
              <div class="hbb-features__h">Gratis y sin compromisos</div>
              <div class="hbb-features__sh">
                Haz tu lista gratis y sin ninguna obligación de comprar nada.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="app-avanzada">
      <div class="bb-container">
        <div class="app-avanzada__container">
          <aside class="app-avanzada__left">
            <div class="app-avanzada__title">La App más avanzada del mercado</div>
            <div class="app-avanzada__description">
              Olvídate de sistemas que no fueron pensados para tu móvil y de Apps que te
              limitan.
            </div>
            <div class="app-avanzada__actions">
              <!-- <a href="#" class="link link--bold link--underline">
                  Découvrez notre application gratuite
                </a> -->
              Descubre nuestra App gratuita con todo lo que necesitas para hacer tu lista
              perfecta.
            </div>
            <div class="app-avanzada__stores" target="_blank">
              <a :href="$t('links.mobileAppUrlAndroid', 'es-ES')">
                <img
                  loading="lazy"
                  src="../../assets/img/logos/logo-app-google.svg"
                  alt=""
                  width="140"
                  height="40"
                />
              </a>
              <a :href="$t('links.mobileAppUrlIos', 'es-ES')" target="_blank">
                <img
                  loading="lazy"
                  src="../../assets/img/logos/logo-app-appstore.svg"
                  alt=""
                  width="140"
                  height="40"
                />
              </a>
            </div>
          </aside>
          <picture class="app-avanzada__cover">
            <img
              src="../../assets//img/covers/mockup_lista_ES.png"
              :alt="$t('home2.appTitle')"
            />
          </picture>
        </div>
      </div>
    </section>
    <main class="home-beneficios">
      <div class="bb-container">
        <div class="home-beneficios__items">
          <section class="home-beneficios__item">
            <div class="home-beneficios__cover">
              <img src="../../assets/img/covers/cover-fr-heart-sale.png" alt="" />
            </div>
            <div class="home-beneficios__right">
              <div class="home-beneficios__title">
                Elige cualquier producto de cualquier tienda
              </div>
              <div class="home-beneficios__description">
                Tienes libertad total para escoger justo lo que quieres.
              </div>
              <ul class="home-beneficios__list">
                <li>Productos únicos de pequeñas marcas</li>
                <li>Las mejores ofertas de las grandes tiendas</li>
              </ul>
            </div>
          </section>
          <section class="home-beneficios__item">
            <div class="home-beneficios__cover">
              <img
                src="../../assets//img/covers/cover-recibiendo-dinero-es.png"
                alt="Lista para recibir dinero en tu hucha."
              />
            </div>
            <div class="home-beneficios__right">
              <div class="home-beneficios__title">
                Lista para recibir dinero en tu hucha
              </div>
              <div class="home-beneficios__description">
                Tu lista de nacimiento incorpora una hucha con la que podrás reunir dinero
                para compras concretas o para causas más generales.
              </div>
              <div class="home-beneficios__actions">
                <div
                  class="link link--bold link--underline"
                  @click="$router.push({ name: 'hucha-de-nacimiento' })"
                >
                  Más información
                </div>
              </div>
            </div>
          </section>
          <section class="home-beneficios__item">
            <div class="home-beneficios__cover">
              <img
                src="../../assets//img/covers/mockup_aportaciones_ES.png"
                alt="Ofrece la posibilidad de compartir las compras más caras"
              />
            </div>
            <div class="home-beneficios__right">
              <div class="home-beneficios__title">
                Ofrece la posibilidad de compartir las compras más caras
              </div>
              <div class="home-beneficios__description">
                Además, tu familia y amigos podrán colaborar con aportaciones parciales
                para comprar las cosas más caras entre todos.
              </div>
            </div>
          </section>
        </div>
      </div>
    </main>
    <div class="feedback-int">
      <div class="bb-container">
        <div class="feedback-int__title">Cosas bonitas que dicen nuestros usuarios</div>
        <div class="feedback-int__items">
          <VueSlickCarousel v-bind="reviewsSlider">
            <div class="feedback-int__item">
              <div class="feedback-user">
                <div class="feedback-user__cover">
                  <img src="../../assets/img/covers/testimonial-marta.jpg" alt="" />
                </div>
                <div class="feedback-user__content">
                  <div class="feedback-user__string">
                    “Esta aplicación va genial para crear tu lista de nacimiento! Tiene
                    muchas opciones y es muy intuitiva. Muy recomendable!”
                  </div>
                  <div class="feedback-user__name">Marta</div>
                </div>
              </div>
            </div>
            <div class="feedback-int__item">
              <div class="feedback-user">
                <div class="feedback-user__cover">
                  <img src="../../assets/img/covers/testimonial-estefania.jpg" alt="" />
                </div>
                <div class="feedback-user__content">
                  <div class="feedback-user__string">
                    “Muy buena solución para facilitar el tema regalos al nacer un bebé!
                    Es súper fácil de usar y muy útil. Lo recomiendo ❤️.”
                  </div>
                  <div class="feedback-user__name">Estefanía</div>
                </div>
              </div>
            </div>
            <div class="feedback-int__item">
              <div class="feedback-user">
                <div class="feedback-user__cover">
                  <img src="../../assets/img/covers/testimonial-ichamar.jpg" alt="" />
                </div>
                <div class="feedback-user__content">
                  <div class="feedback-user__string">
                    “App súper útil e intuitiva para hacer la wishlist del bebé. Muy
                    recomendable.”
                  </div>
                  <div class="feedback-user__name">Pier</div>
                </div>
              </div>
            </div>
          </VueSlickCarousel>
        </div>
      </div>
    </div>
    <section class="acomp">
      <div class="bb-container">
        <div class="acomp__container">
          <div class="acomp__left">
            <div class="acomp__title">Acompañamos tu embarazo💛</div>
            <div class="acomp__description">
              <p>
                Preparar la llegada de un bebé es una experiencia maravillosa, pero seamos
                francos, durante el proceso surgen muchas preguntas que pueden resultar
                estresantes.
              </p>
              <p>
                Nuestros emails resolverán tus dudas y serán un simpático recordatorio de
                tu calendario de embarazo.
              </p>
            </div>
          </div>
          <div class="acomp__right">
            <img
              src="../../assets/img/covers/cover-acompanamos-embarazo.png"
              alt="Preparar la llegada de un bebé es una experiencia maravillosa, pero seamos francos, durante el proceso surgen muchas preguntas que pueden resultar estresantes."
            />
          </div>
        </div>
      </div>
    </section>
    <section class="cta-yellow-fw">
      <div class="bb-container">
        <div class="cta-yellow-fw__cta">
          <div class="cta-yellow-fw__left">
            <div class="cta-yellow-fw__title">
              Y tú, ¿ya lo tienes todo listo para la llegada de tu bebé?
            </div>
            <div class="cta-yellow-fw__actions">
              <bbButton
                label="Empieza tu lista ahora"
                class="button--accent button--lg"
                @click="$router.push({ name: 'signup' })"
              />
              <div
                class="link link--bold link--underline"
                @click="$router.push({ name: 'guest', params: { id: exampleListId } })"
              >
                Ver una lista de ejemplo
              </div>
            </div>
          </div>
          <div class="cta-yellow-fw__cover">
            <img src="../../assets/img/covers/cover-peluche-ropa-bb-comoda.jpg" alt="" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import bbButton from "../../components/buttons/bbButton.vue";

export default {
  name: "HomePageES",
  metaInfo() {
    return {
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "SoftwareApplication",
            name: "HelloBB",
            operatingSystem: [
              //no estic segur del format per posar més d’un valor
              "ANDROID",
              "IOS",
            ],
            applicationCategory: "LifestyleApplication",
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: "4.5", //no aconsegueixo veure ratings, tu pots?
              ratingCount: "20", //no aconsegueixo veure ratings, tu pots?
            },
            offers: {
              "@type": "Offer",
              price: "0",
            },
          },
        },
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "HelloBB",
            legalName: "HelloBB S.L.",
            url: "https://www.hellobb.net",
            logo: "https://www.hellobb.net/logo-hellobb-dark.svg", //posar URL on tenim el logo
            description:
              "Pon en tu lista de nacimiento cualquier cosa de cualquier tienda.",
            foundingDate: "2022",
            address: {
              "@type": "PostalAddress",
              streetAddress: "C/ Pompeu Fabra 36",
              addressLocality: "Tortosa",
              addressRegion: "Tarragona",
              postalCode: "43500",
              addressCountry: {
                "@type": "Country",
                name: "Spain", //en anglés millor?
              },
            },
            contactPoint: {
              "@type": "ContactPoint",
              contactType: "customer support",
              email: "hola@hellobb.net",
            },
            sameAs: [
              "https://www.instagram.com/holahellobebe/",
              "https://www.facebook.com/holahelloBB",
              "https://www.pinterest.es/holahelloBB/",
              "https://www.crunchbase.com/organization/hellobb",
            ],
          },
        },
      ],
      title: "HelloBB | Tu lista de nacimiento sin límites",
      meta: [
        {
          name: "description",
          content:
            "Una lista de nacimiento digital en la que puedes poner cualquier cosa de cualquier tienda. Fácil de usar y compartir. ¡Tan única como tú!",
        },
        {
          itemprop: "image",
          content: "https://www.hellobb.net/images/cover-portada.08a0882.png",
        },
        {
          property: "og:url",
          content: "https://www.hellobb.net",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:title",
          content: "HelloBB | Tu lista de nacimiento sin límites",
        },
        {
          property: "og:description",
          content:
            "Una lista de nacimiento digital en la que puedes poner cualquier cosa de cualquier tienda. También puedes recaudar dinero y pedir favores. ¡Tan única como tú!",
        },
        {
          property: "og:image",
          content: "https://www.hellobb.net/images/cover-portada.08a0882.png",
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          name: "twitter:title",
          content: "HelloBB | Tu lista de nacimiento sin límites",
        },
        {
          name: "twitter:description",
          content:
            "Una lista de nacimiento digital en la que puedes poner cualquier cosa de cualquier tienda. También puedes recaudar dinero y pedir favores. ¡Tan única como tú!",
        },
        {
          name: "twitter:image",
          content: "https://www.hellobb.net/images/cover-portada.08a0882.png",
        },
      ],
      link: [
        { rel: "canonical", href: "https://www.hellobb.net/lista-de-nacimiento" },
        { rel: "alternate", href: "https://www.hellobb.net/liste-de-naissance", hreflang: "fr" },
        { rel: "alternate", href: "https://www.hellobb.net/lista-di-nascita", hreflang: "it" },
        { rel: "alternate", href: "https://www.hellobb.net/lista-de-nacimiento", hreflang: "es-es" },
        { rel: "alternate", href: "https://www.hellobb.net/mesa-de-regalos", hreflang: "es-mx" },
        { rel: "alternate", href: "https://www.hellobb.net/mesa-de-regalos", hreflang: "es-co" },
        { rel: "alternate", href: "https://www.hellobb.net/mesa-de-regalos", hreflang: "es-ar" },
        { rel: "alternate", href: "https://www.hellobb.net/mesa-de-regalos", hreflang: "es-cl" },
        { rel: "alternate", href: "https://www.hellobb.net/mesa-de-regalos", hreflang: "es-uy" },
        { rel: "alternate", href: "https://www.hellobb.net/mesa-de-regalos", hreflang: "es-pe" },
      ],
    };
  },
  components: {
    bbButton,
    VueSlickCarousel,
  },
  data() {
    return {
      shopSlider: {
        slidesToShow: 8,
        arrows: false,
        autoplay: true,
        dots: false,
        variableWidth: false,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "20px",
        edgeFriction: 0.35,
        speed: 500,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            variableWidth: true,
            breakpoint: 767,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            variableWidth: true,
            breakpoint: 575,
            settings: {
              slidesToShow: 2,
              variableWidth: true,
            },
          },
        ],
      },
      reviewsSlider: {
        slidesToShow: 3,
        arrows: false,
        autoplay: true,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 767,
            settings: {
              centerMode: true,
              slidesToShow: 1,
            },
          },
        ],
      },
    };
  },
  computed: {
    exampleListId() {
      return process.env.PREVIEW_LIST_ES_ID;
    },
  },
};
</script>

<style lang="scss" scoped></style>
