<template>
  <div class="bb-onboarding">
    <div class="bg-cercles bb-onboarding__container show-back">
      <ResponsiveMobile :targetClass="['bb-onboarding-back']">
        <div class="back" v-if="!isNewUser">
          <div class="back__back">
            <a href="#" @click.prevent="previous()">
              <div class="back__name">
                <img
                  src="../../assets/img/ico/ico-arrow-back.svg"
                  :alt="$t('generic.goback')"
                  class="back__arrow"
                />
              </div>
            </a>
          </div>
        </div>
      </ResponsiveMobile>

      <div class="bb-onboarding__wrapper">
        <div class="bb-onboarding__header">
          <div class="bb-onboarding__step" v-if="totalSteps > 1">{{ $t("onboarding.configuring_your_list") }}: {{ currentStepNumber }}/{{ totalSteps }}</div>
          <div class="bb-onboarding__title">{{ $t("onboarding.congratulations") }}</div>
          <div class="bb-onboarding__description">
            {{ $t("onboarding.if_you_are_here") }}
          </div>
        </div>
        <div class="bb-onboarding__content">

          <div class="bb-onboarding__form">

            <div class="bb-form-item" v-if="false">
              <label class="bb-form-label">{{ $t("onboarding.areYouWaitingABaby") }}</label>

              <div class="bb-radio">
                <div class="bb-radio__items">
                  <div class="bb-radio__item">
                    <div class="bb-form-checkbox">
                      <input type="radio" name="checkbox" id="yes" value="yes" @click="enableButton" />
                      <label for="yes" class="bb-form-checkbox__label">
                        {{ $t("generic.yes") }}
                      </label>
                    </div>
                  </div>
                  <div class="bb-radio__item">
                    <div class="bb-form-checkbox">
                      <input type="radio" name="checkbox" id="no" value="no" @click="enableButton" />
                      <label for="no" class="bb-form-checkbox__label">
                        {{ $t("generic.no") }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div class="bb-form-item">
              <label for="childBirthday" class="bb-form-label">{{ $t("onboarding.approximateBirthdate") }}</label>
              <date-picker
                id="childBirthday"
                name="childBirthday"
                v-model="childBirthday"
                @blur="childBirthdayBlur"
                :input-class="{
                  'bb-form--success': childBirthdayIsValid,
                  'bb-form--danger': childBirthdayHasError,
                }"
              ></date-picker>
              <div class="bb-form-error" v-show="childBirthdayHasError">
                {{ $t("generic.field_is_required") }}
              </div>
            </div>

            <div class="bb-form-item">
              <!--label class="bb-form-label">{{ $t("onboarding.noBaby") }}</label-->

              <div class="bb-radio">
                <div class="bb-radio__items">
                  <div class="bb-radio__item">
                    <div class="bb-form-checkbox">
                      <input
                        type="checkbox"
                        name="noBaby"
                        id="noBaby"
                        value="no"
                        v-model="babyCheckbox"
                      />
                      <label for="noBaby" class="bb-form-checkbox__label">
                        {{ $t("onboarding.noBaby") }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>

          <div class="bb-onboarding__actions bb-onboarding__actions--no-margin">
            <loading-button v-if="loading"></loading-button>
            <button v-else class="button button--primary button--block" @click="next" :disabled="disabled">
              {{ $t("generic.next") }}
            </button>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

// eslint-disable-next-line no-unused-vars
//import moment_es from "moment/locale/es";

import LoadingButton from "../../components/LoadingButton";
import DatePicker from "../../components/DatePicker";
import ResponsiveMobile from "../../components/responsive/ResponsiveMobile";

import Steps from "./mixins/Steps";

moment.locale("moment_es");
const crypto = require("crypto");

export default {
  name: "OnboardingStep1",
  components: {
    LoadingButton,
    DatePicker,
    ResponsiveMobile,
  },
  mixins: [
    Steps,
  ],
  props: {
    email: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      loading: false,
      childBirthdayDirty: false,
      childBirthdayIntern: null,
      conditionToDisable: true,
      selectedOption: null,
      babyCheckbox: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "user"
    }),
    childBirthdayHasError() {
      //return (this.selectedOption === "yes" && this.childBirthday === null || this.childBirthday === "Invalid date" || this.childBirthday === "Fecha inválida") && this.childBirthdayDirty;
      return !this.babyCheckbox && (this.childBirthday === null || this.childBirthday === "Invalid date" || this.childBirthday === "Fecha inválida") && this.childBirthdayDirty;
    },
    childBirthdayIsValid() {
      return this.childBirthday !== null && this.childBirthday !== "Invalid date" && this.childBirthday !== "Fecha inválida";
    },
    childBirthday: {
      get() {
        const birthday = this.childBirthdayIntern;
        if (
          birthday === "0001-01-01T00:00:00" ||
          birthday === null ||
          (birthday === undefined) | (birthday === "")
        ) {
          return null;
        }
        return moment(birthday).format("yyyy-MM-DD");
      },
      set(value) {
        this.childBirthdayIntern = value;
      }
    },
    disabled() {
      return (
        this.babyCheckbox === null ||
        (
          !this.babyCheckbox && (
            this.childBirthday === null ||
            this.childBirthday === "Invalid date"
          )
        )
      );
    }
  },
  methods: {
    childBirthdayBlur() {
      this.childBirthdayDirty = true;
    },
    updateUser() {
      this.$store.commit("updateUserField", {
        field: "childBirthday",
        value: this.childBirthday
      });
    },
    async previous() {
      await this.goToPreviousStep(this.$options.name);
    },
    async next() {
      this.loading = true;
      try {
        const stepData = {
          childBirthday: this.childBirthday,
          //shouldUpdateBabyRegistryFlag: this.selectedOption === "no",
          shouldUpdateBabyRegistryFlag: !!this.childBirthday,
        };

        if (this.isNewUser) {
          await this.saveOnboardingStep1(stepData);
        }

        await this.goToNextStep({
          [this.$options.name]: stepData,
        });
      } finally {
        this.loading = false;
      }
    },
    setModalShownCookie() {
      const days = 30;
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + days);

      const currentTimestamp = new Date().getTime().toString();
      const randomValue = Math.random().toString();
      const uniqueHash = crypto
        .createHash("sha256")
        .update(currentTimestamp + randomValue)
        .digest("hex");

      document.cookie = `modalShown=${uniqueHash}; expires=${expirationDate.toUTCString()}; path=/`;

      // Acceder a la cookie y mostrar su valor en la consola
      const cookieValue = document.cookie.replace(
        /(?:(?:^|.*;\s*)modalShown\s*=\s*([^;]*).*$)|^.*$/,
        "$1"
      );
    },
    /*
    enableButton(event) {
      this.conditionToDisable = false;
      this.selectedOption = event.target.value;
    },
    */
  },
  mounted() {
    this.$trackPinterest("signup", this.user.email);
    if (this.$route.query.newuser) {
      this.setModalShownCookie();
    }
  }
}
</script>
