import { mapGetters } from "vuex";

import OnboardingStep1Save from "./OnboardingStep1Save";

// NOTE: Steps are defined in the methods.

export default {
    mixins: [
        OnboardingStep1Save,
    ],
    computed: {
        ...mapGetters({
            countryCode: "countryCode",
        }),
        onboardingListType() {
            return this.$route?.params?.listType || "";
        },
        isMultiList() {
            return !!this.onboardingListType;
        },
        isNewUser() {
            return (
                typeof this.$route.meta?.isNewUser === "undefined" ||
                this.$route.meta?.isNewUser === true
            );
        },
        currentlyInMultiListStep() {
            return this.$route.meta?.isMultiListStep;
        },
        dataFromAllSteps() {
            try {
                if (this.$route.query.newListState) {
                    return JSON.parse(this.$route.query.newListState);
                }
            } catch (err) {
                console.error(err);
            }

            return {};
        },
        allSteps() {
            return this.createSteps((addStep) => {
                if (this.isMultiList) {
                    const listType = this.onboardingListType;
                    return this.configureMultiListOnboardingSteps(listType, addStep);
                }

                if (this.isOnboardingForExternalEcommerce) {
                    return this.configureModalOnboardingSteps(addStep);
                }

                return this.configureWebOnboardingSteps(addStep);
            });
        },
        isModal() {
            return this.$route?.query?.modal === "1";
        },
        isSocial() {
            return this.$route?.query?.social === "1";
        },
        redirectTo() {
            return this.$route?.query?.wantedRoute || "";
        },
        isOnboardingForExternalEcommerce() {
            return this.isModal || this.isSocial;
        },
        onboardingHappensInsideModal() {
            return this.isModal && !this.isSocial && !this.redirectTo;
        },

        // See the `createSteps` method if you want to see how the returned
        // object is created.
        currentStepInfo() {
            if (this.onboardingHappensInsideModal) {
                return this.allSteps.find((stepInfo) => stepInfo.componentName === this.$options.name);
            }

            return this.allSteps.find((stepInfo) => stepInfo.routeName === this.$route.name);
        },

        currentStepNumber() {
            return this.currentStepInfo?.stepNumber ?? "?";
        },
        totalSteps() {
            const last = this.allSteps.findLast((step) => step.count);

            return last.stepNumber;
        },
        previousStepRouteName() {
            if (this.isMultiList && !this.currentlyInMultiListStep) {
                return "owner-list";
            }

            const currentStepInfo = this.currentStepInfo;
            const i = currentStepInfo.index;

            return this.allSteps[i - 1]?.routeName || "NewList";
        },
        nextStepRouteName() {
            if (this.isMultiList && !this.currentlyInMultiListStep) {
                return this.allSteps[0]?.routeName;
            }

            const currentStepInfo = this.currentStepInfo;
            const i = currentStepInfo.index;

            return this.allSteps[i + 1]?.routeName;
        },
        nextStepIndex() {
            if (this.isMultiList && !this.currentlyInMultiListStep) {
                if (this.allSteps.length === 0) {
                    // No steps.
                    return -1;
                }

                return 0;
            }

            const currentStepInfo = this.currentStepInfo;

            const i = currentStepInfo.index + 1;

            if (!this.allSteps[i]) {
                return -1;
            }

            return i;
        },
        skipStepRouteName() {
            const currentStepInfo = this.currentStepInfo;

            const skip = currentStepInfo.skip;
            if (!skip) {
                return this.nextStepRouteName;
            }

            const i = currentStepInfo.index;

            return this.allSteps[i + 1 + skip]?.routeName;
        },
        skipStepIndex() {
            const currentStepInfo = this.currentStepInfo;

            const skip = currentStepInfo.skip;
            if (!skip) {
                return this.nextStepIndex;
            }

            const i = currentStepInfo.index + 1 + skip;

            if (!this.allSteps[i]) {
                return -1;
            }

            return i;
        },
        isLastStep() {
            return this.nextStepIndex === -1;
        },
        isLastSkipStep() {
            return this.skipStepIndex === -1 || Number.isNaN(this.skipStepIndex);
        },
    },
    methods: {

        //=====================================================================
        // Steps are defined here.
        //=====================================================================
        //
        // `addStep` is a function that takes a route name as first argument,
        // and an optional object as a second argument.
        //
        // The optional object (second argument) can contain the properties:
        //
        //     - `skip`: Number. How many steps to skip when calling the
        //       `skipStep` method. If missing, the default value is `0`, and in
        //       this case `skipStep()` will behave the same as `goToNextStep()`.
        //
        //     - `count`: Boolean. If `false`, this step will be ignored when
        //       calculating the amount of steps. If missing, the default value
        //       is `true`.
        //
        //     - `componentName`: String. Name of the INNER component used by
        //       the route to show this specific step. This component should
        //       not be the component of the page.
        configureMultiListOnboardingSteps(listType, addStep) {
            // NOTE: `addStep` receives route names, not component names.

            const isNewUser = this.isNewUser;

            switch (listType) {
            case "baby-registry":
            case "babyshower":
                {
                    addStep("onboarding1");

                    if (isNewUser) {
                        addStep("onboardingColaborador", { skip: 1 });
                        addStep("OnboardingStepInvitacionEnviada", { count: false});

                        if (this.countryCode === "ES") {
                            //addStep("OnboardingStepHowDidYouFindUs");
                        }
                        if (this.countryCode === "MX") {
                            addStep("OnboardingStepWhyDidYouRegister");
                        }

                        //addStep("onboardingSurvey");

                        if (this.countryCode === "ES") {
                            addStep("OnboardingStepListTemplate");
                        }
                    }
                }
                break;
            case "wedding":
                {
                    if (isNewUser) {
                        addStep("onboardingColaborador", { skip: 1 });
                        addStep("OnboardingStepInvitacionEnviada", { count: false});
                    }
                }
                break;
            default:
                break;
            }
        },

        configureWebOnboardingSteps(addStep) {
            // NOTE: `addStep` receives route names, not component names.

            addStep("onboarding1");
            addStep("onboardingColaborador", { skip: 1 });
            addStep("OnboardingStepInvitacionEnviada", { count: false});

            if (this.countryCode === "ES") {
                //addStep("OnboardingStepHowDidYouFindUs");
            }
            if (this.countryCode === "MX") {
                addStep("OnboardingStepWhyDidYouRegister");
            }

            //addStep("onboardingSurvey");

            if (this.countryCode === "ES") {
                addStep("OnboardingStepListTemplate");
            }

            //addStep("onboarding2");
        },

        configureModalOnboardingSteps(addStep) {
            // IMPORTANT: Any component mentioned here must also be registered
            // in the `ExternalEcommerceOnboardingModal` component.

            // Syntax: addStep(ROUTE_NAME, { componentName: COMPONENT_NAME })

            addStep("OnboardingStepEcommerceBirthdate", { componentName: "OnboardingStepEcommerceBirthdateContent" });
        },

        //=====================================================================
        // Steps are defined above.
        //=====================================================================

        createSteps(callback) {
            const steps = [];
            let nextStepNumber = 1;

            const DEFAULT_OPTIONS = {
                skip: 0,
                count: true,
            };

            const addStep = (routeName, additionalStepInfo = {}) => {
                if (this.isMultiList) {
                    if (this.isNewUser) {
                        routeName = `MultiList${routeName}`;
                    } else {
                        routeName = `NewList${routeName}`;
                    }
                }
                const stepInfo = {
                    ...DEFAULT_OPTIONS,
                    ...(additionalStepInfo || {}),

                    stepNumber: -1,
                    index: steps.length,

                    routeName,
                };
                if (stepInfo.count) {
                    stepInfo.stepNumber = nextStepNumber++;
                }

                steps.push(stepInfo);
            }

            callback(addStep);

            return steps;
        },
        goToPreviousStep(stepNameToRemoveFromData) {
            if (this.onboardingHappensInsideModal) {
                throw new Error("Not implemented yet.");
            }

            let newListState = this.dataFromAllSteps;
            if (stepNameToRemoveFromData) {
                newListState = Object.fromEntries(
                    Object.entries(this.dataFromAllSteps)
                        .filter(([stepName, stepData]) => stepName !== stepNameToRemoveFromData)
                );
            }

            const query = {
                ...this.$route.query,
            };
            if (!this.isNewUser) {
                query.newListState = JSON.stringify(newListState);
            }

            return this.$router.push({
                name: this.previousStepRouteName,
                query: query,
            });
        },
        goToNextStep(data) {
            if (this.onboardingHappensInsideModal) {
                return this.$emit("step", this.nextStepIndex);
            }

            const newListState = {
                ...this.dataFromAllSteps,
                ...data,
            };

            if (this.isLastStep) {
                return this.finishOnboarding(newListState);
            }

            const query = {
                ...this.$route.query,
            };
            if (!this.isNewUser) {
                query.newListState = JSON.stringify(newListState);
            }

            return this.$router.push({
                name: this.nextStepRouteName,
                query: query,
            });
        },
        skipStep() {
            if (this.isLastSkipStep) {
                return this.finishOnboarding();
            }

            if (this.onboardingHappensInsideModal) {
                return this.$emit("step", this.skipStepIndex);
            } else {
                return this.$router.push({ name: this.skipStepRouteName, query: this.$route.query });
            }
        },
        async createList(stepsData) {
            const listType = this.onboardingListType;

            await this.$store.dispatch("createList", {
                isDefault: true,
                listType,
            });
            await this.$store.dispatch("updateUserLists");

            for (const [stepName, stepData] of Object.entries(stepsData)) {
                const saveMethodName = `save${stepName}`;
                const save = this[saveMethodName]?.bind(this);
                if (typeof save !== "function") {
                    throw new Error(`could not find method ${saveMethodName}`);
                }

                await save(stepData);
            }

            await this.$store.dispatch("getOwnerList");
        },
        async finishOnboarding(stepsData) {
            if (!this.isNewUser) {
                await this.createList(stepsData);
            }

            this.$store.commit("reloadLandbot");

            if (this.redirectTo) {
                window.location.href = this.redirectTo;

                return;
            }
            if (this.isSocial) {
                return this.$router.push({ name: "external-ecommerce-social-login-success", query: this.$route.query });
            }

            if (this.$utils.isApp()) window.location.href = "/mi-lista#onboarding-end";
            else return this.$router.push({ name: "owner-list" });
        },

        startMultiListOnboardingSteps() {
            this.goToNextStep();
        },
    },
    mounted() {
        if (this.isMultiList && !this.currentlyInMultiListStep) {
            return this.startMultiListOnboardingSteps();
        }
    },
}
