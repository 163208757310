<template>
  <section class="search-next">
    <header class="search-next__header search-next__header--simple">
      <div class="search-next__title search-next__title--center">
        Pega el enlace de un producto (No importa de qué tienda).
      </div>
    </header>
    <main class="search-next__search">
      <mq-layout mq="m992+">
        <input
          type="text"
          class="search-next__input"
          placeholder="https://www.example.com"
          :value="inputText"
          @input="handleInput"
          @keyup.enter="search"
          ref="searchInput"
        />
      </mq-layout>
      <mq-layout :mq="['m480', 'm576', 'm768']">
        <input
          type="text"
          class="search-next__input"
          placeholder="https://www.example.com"
          :value="inputText"
          @input="handleInput"
          @keyup.enter="search"
          ref="searchInput"
        />
      </mq-layout>
      <span
        class="search-next__input-ico search-next__input-ico--url"
        @click="search"
      >
        <i class="uil uil-link"></i>
      </span>
    </main>

    <div class="search-next__add-link-test">
      <div class="add-link-test">
        <div class="add-link-test__link" v-if="!isLink && false">
          <i class="uil uil-arrow-right"></i> Haz una prueba con
          <u :disabled="!isLink" @click.prevent="replaceLinkWithExample()">
            este enlace.
          </u>
        </div>
        <div class="add-link-test__link" v-else-if="false">
          <i class="uil uil-arrow-right"></i>
          Dale al botón para cargarlo.
        </div>
        <div class="add-link-test__actions">
          <bbButton
            v-show="false"
            label="¿Cómo funciona?"
            class="button--sm button--outline-dark"
            @click="showDialog = !showDialog"
          />
        </div>
        <ModalDialog
          :show="showDialog"
          @close="closeDialog()"
          target-class="modal__card--login"
        >
          <div class="content-modal">
            <div class="content-modal__content">
              <div class="modal-hiw">
                <header class="modal-hiw__title">¿Cómo funciona?</header>
                <picture class="modal-hiw__picture">
                  <img
                    src="../../../assets//img/covers/cover-elige-compact-es.svg"
                    alt="¿Cómo funciona?"
                  />
                </picture>
                <div class="modal-hiw__subtitle">
                  Guarda cosas en tu lista de HelloBB así:
                </div>
                <main class="modal-hiw__items">
                  <div class="modal-hiw__item">
                    <div class="modal-hiw__step">
                      <div class="modal-hiw__cercle">1</div>
                    </div>
                    <div class="modal-hiw__desc">
                      Busca en la web de cualquier tienda algo que te guste.
                    </div>
                  </div>
                  <div class="modal-hiw__item">
                    <div class="modal-hiw__step">
                      <div class="modal-hiw__cercle">2</div>
                    </div>
                    <div class="modal-hiw__desc">Copia su enlace.</div>
                  </div>
                  <div class="modal-hiw__item">
                    <div class="modal-hiw__step">
                      <div class="modal-hiw__cercle">3</div>
                    </div>
                    <div class="modal-hiw__desc">Pégalo en el formulario de arriba.</div>
                  </div>
                </main>
                <aside class="modal-hiw__actions">
                  <bbButton
                    label="Entendido"
                    class="button--block"
                    @click="closeDialog()"
                  />
                </aside>
              </div>
            </div>
          </div>
        </ModalDialog>
      </div>
    </div>

    <AddFund ref="refAddButton" />
  </section>
</template>

<script>
import bbButton from "../../../components/buttons/bbButton.vue";
import { mapGetters } from "vuex";
import axios from "axios";
import FundAddButton from "./FundAddButton.vue";
import AddFund from "./AddFund.vue";
import "floating-vue/dist/style.css";
import { VTooltip } from "floating-vue";
import ModalDialog from "../../../components/ui/ModalDialog";

import LinkMixin from "./Mixins/Link";

export default {
  name: "SearchConstructorInitialSearch",
  components: {
    FundAddButton,
    bbButton,
    AddFund,
    ModalDialog,
  },
  directives: {
    tooltip: VTooltip,
  },
  mixins: [
    LinkMixin,
  ],
  data() {
    return {
      inputText: "",
      currentSearchTerm: "",
      currentPage: 1,
      suggestions: ["Carritos", "Capazos", "Biberones", "Tronas", "Pañales"],
      dynamicTooltipContent: "",
      showDialog: false,
    };
  },
  computed: {
    ...mapGetters({
      searchState: "searchState",
      constructorQuery: "constructorQuery",
      products: "productsConstructor",
      contributionsFlag: "contributionsFlag",
      hidePiggyBank: "hidePiggyBank",
    }),
    dynamicTooltip() {
      return {
        html: false,
        content: this.dynamicTooltipContent,
        handleResize: false,
        triggers: ["hover", "focus"],
      };
    },
    isFullUrl() {
      try {
        const url = new URL(this.fixedUrl);
        if (url.pathname === "/") {
          return false;
        }

        return true;
      } catch (err) {
        return false;
      }
    },
    contributionsEnabled() {
      return this.user?.stripeAccountId != null && this.user?.stripeAccountId != "";
    },
  },
  methods: {
    search() {
      if (!this.inputText.trim()) {
        window.mixpanel.track("Cerca lliure", {
          text: this.inputText,
          error: "Format empty URL",
        });
        this.$emit("error", this.$t("constructor.emptyUrl"));
        this.$refs.searchInput.focus();
        return;
      }
      if (!this.isLink) {
        window.mixpanel.track("Cerca lliure", {
          text: this.inputText,
          error: "Format no URL",
        });
        this.$emit("error", this.$t("constructor.notUrl"));
        return;
      }
      if (!this.isFullUrl) {
        this.$emit("error", this.$t("constructor.notFullUrl"));
        window.mixpanel.track("Cerca lliure", {
          text: this.inputText,
          error: "URL arrel",
        });
        return;
      }

      const fixedUrl = this.fixedUrl;

      window.mixpanel.people.set_once({ "Primera cerca lliure": this.inputText });
      window.mixpanel.track("Cerca lliure", {
        text: this.inputText,
      });
      if (this.$utils.isApp())
        window.location.href =
          window.location.pathname +
          "#addfree" +
          "?u=" +
          encodeURIComponent(fixedUrl);
      else {
        this.$router.push({
          name: "add-product",
          query: { url: encodeURI(fixedUrl) },
        });
      }
    },
    resetSearch() {
      this.inputText = "";
      this.currentSearchTerm = "";
      this.currentPage = 1;
      this.$store.commit("setConstructorQuery", "");
      this.$store.dispatch("resetProductsConstructor");
    },
    handleInput(event) {
      this.inputText = event.target.value;
    },
    addFund() {
      this.$refs.refAddButton.addFund();
    },
    replaceLinkWithExample() {
      this.inputText = "https://www.babybjorn.es/productos/hamacas/hamaca-bliss/";
    },
    closeDialog() {
      this.showDialog = false;
    },
  },
  async mounted() {
    this.dynamicTooltipContent =
      "Recolecta dinero para lo que necesites (pañales, guarderías, causas benéficas...)";
  },
};
</script>
