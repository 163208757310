<template>
  <div class="bb-onboarding">
    <div class="bg-cercles bb-onboarding__container show-back">
      <ResponsiveMobile :targetClass="['bb-onboarding-back']">
        <div class="back" v-if="!isNewUser">
          <div class="back__back">
            <a href="#" @click.prevent="previous()">
              <div class="back__name">
                <img
                  src="../../assets/img/ico/ico-arrow-back.svg"
                  :alt="$t('generic.goback')"
                  class="back__arrow"
                />
              </div>
            </a>
          </div>
        </div>
      </ResponsiveMobile>

      <div class="bb-onboarding__wrapper">
        <div class="bb-onboarding__header bb-onboarding__header--wrap">
          <div class="bb-onboarding__step" v-if="totalSteps > 1">{{ $t("onboarding.configuring_your_list") }}: {{ currentStepNumber }}/{{ totalSteps }}</div>

          <div class="bb-onboarding__title">
            {{ $t("onboarding.do_you_want_to_invite_title") }}
          </div>
          <div class="bb-onboarding__description">
            {{ $t("onboarding.do_you_want_to_invite_description") }}
          </div>
        </div>
        <div class="bb-onboarding__content">
          <section class="bb-onboarding__form">
            <div class="bb-form-fieldset">
              <!-- Agregar colaborador  -->
              <template>
                <div class="bb-form-item">
                  <div class="bb-form-label">{{ $t("onboarding.form_name") }}</div>
                  <input
                    v-model="collaboratorName"
                    type="text"
                    class="bb-form"
                    :placeholder="$t('onboarding.form_name_placeholder')"
                    name="collaboratorName"
                    id="collaboratorName"
                    @input="collaboratorNameHasError = false"
                  />
                  <div class="bb-form-error" v-show="collaboratorNameHasError">
                    {{ $t("generic.field_is_required") }}
                  </div>
                </div>
                <div class="bb-form-item">
                  <div class="bb-form-label">{{ $t("onboarding.form_email") }}</div>
                  <input
                    v-model="collaboratorEmail"
                    type="text"
                    class="bb-form"
                    :placeholder="$t('onboarding.form_email_placeholder')"
                    name="collaboratorEmail"
                    id="collaboratorEmail"
                    @input="resetEmailErrors()"
                  />
                  <div class="bb-form-error" v-show="collaboratorEmailHasError">
                    {{ $t("generic.field_is_required") }}
                  </div>
                  <div class="bb-form-error" v-show="!collaboratorEmailHasError && collaboratorEmailExist">
                    {{ $t("onboarding.account_already_exists") }}
                  </div>
                  <div class="bb-form-error" v-show="!collaboratorEmailHasError && !collaboratorEmailExist && collaboratorEmailFormat">
                    {{ $t("onboarding.invalid_email_format") }}
                  </div>
                </div>
              </template>
            </div>
          </section>
          <div class="bb-onboarding__actions">
            <loading-button v-if="loading"></loading-button>
            <button v-else @click.prevent="save" class="button button--primary button--block">
              {{ $t("onboarding.send_invitation") }}
            </button>
            <div class="link link--underline link--bold" @click.prevent="skipStep()">
              {{ $t("onboarding.i_will_do_it_later") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import LoadingButton from "../../components/LoadingButton.vue";
import ResponsiveMobile from "../../components/responsive/ResponsiveMobile";
import error from "../Error.vue";
import Steps from "./mixins/Steps";

export default {
  name: "OnboardingStepColaborador",
  components: {
    LoadingButton,
    ResponsiveMobile,
  },
  mixins: [
    Steps,
  ],
  data() {
    return {
      collaboratorName: "",
      collaboratorEmail: "",
      collaboratorEmailExist: false,
      loading: false,
      collaboratorEmailFormat: false,
      collaboratorNameHasError: false,
      collaboratorEmailHasError: false,
    };
  },
  computed: {
    ...mapGetters({
      list: "ownerList",
      showAlertKey: "showAlertKey",
      showAlert: "showAlert",
      user: "user"
    }),
    disabled() {
      const isNameInvalid = this.collaboratorName.trim() === "" || this.collaboratorEmail.trim() === "";

      const isCollaboratorInvalid = !this.collaboratorName || !this.collaboratorEmail || this.collaboratorEmailFormat;

      return isNameInvalid || isCollaboratorInvalid;
    },
  },

  methods: {
    validate() {
      this.collaboratorNameHasError = (
        this.collaboratorName.trim() === ""
      );

      this.collaboratorEmailHasError = (
        this.collaboratorEmail.trim() === ""
      );

      this.collaboratorEmailExist = false;
      this.collaboratorEmailFormat = !this.validateEmailFormat(this.collaboratorEmail);

      const allValid = [
        this.collaboratorNameHasError,
        this.collaboratorEmailHasError,
        this.collaboratorEmailFormat,
      ].every(v => !v);

      return allValid;
    },
    resetEmailErrors() {
      this.collaboratorEmailHasError = false;
      this.collaboratorEmailExist = false;
      this.collaboratorEmailFormat = false;
    },
    async save() {
      if (!this.validate()) {
        return;
      }

      this.loading = true;
      if (this.collaboratorName && this.collaboratorEmail) {
        const collaboratorData = {
          email: this.collaboratorEmail,
          name: this.collaboratorName
        };

        try {
          const response = await this.$store.dispatch("sendInvitation", collaboratorData);

          if (response && response.status === 200) {
            window.mixpanel.track("Invitar col·laborador", {
              "Nombre del colaborador": this.collaboratorName,
              "Email del colaborador": this.collaboratorEmail,
            });
            await this.goToNextStep();
          } else {
            console.log(error)
          }
        } catch (error) {
          console.log(error);
          this.loading = false;
          if (error.response && error.response.status === 409) {
            this.collaboratorEmailExist = true;
          } else {
            this.$alert.error("Algo ha ido mal. No hemos podido guardar los cambios.", this.key);
          }

        }
      }
    },
    validateEmailFormat(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    async previous() {
      await this.goToPreviousStep(this.$options.name);
    },
  },
};
</script>
