<template>
  <div>
  </div>
</template>

<script>
import Steps from "./mixins/Steps";

export default {
  name: "OnboardingMultiLista",
  mixins: [
    Steps,
  ],
};
</script>
