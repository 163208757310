<template>
  <div class="bb-login">
    <div class="bb-login__container">
      <div class="login-box">
        <h1 class="login-box__title">{{ $t("login.accessToYourList", lang) }}</h1>

        <Form class="form" :operation="operation" :valid="valid" :error="error">
          <template>
            <div class="login-box__inputs">
              <InputText
                :errorLabel="emailError"
                :label="$t('login.yourEmail', lang) + ':'"
                :placeholder="$t('login.yourEmail', lang)"
                @focus="emailError = ''"
                id="email"
                v-model="email"
              />
              <InputText
                :errorLabel="passwordError"
                :label="$t('login.password', lang)"
                :placeholder="$t('login.password', lang)"
                @focus="passwordError = ''"
                id="password"
                type="password"
                v-model="password"
              />
            </div>
          </template>

          <template slot="actions">
            <div class="login-box__actions">
              <input
                type="submit"
                :disabled="!valid"
                :value="$t('login.logIn', lang)"
                data-wait="Un segundo..."
                class="button button--primary button--block button--lg"
              />
            </div>

            <div class="login-box__recovery">
              {{ $t("login.forgotPassword", lang) }}
              <router-link to="/recoverpwd"> {{ $t("login.forgotPasswordCTA", lang) }} </router-link>
            </div>

            <login-social
              :show="true"
              :showFacebook="false"
              :showLabel="true"
              :wantedRoute="$route.query.wantedRoute || ''"
              class="login-box__social"
              color="white"
              size="large"
              origin="plugin"
              :forcedLang="lang"
              @loggedIn="handleLoggedIn()"
              @popup="$emit('popup')"
              :retailerName="retailerName"
              verticalAlign="true"
            ></login-social>
          </template>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import bbButton from "../../../components/buttons/bbButton.vue";
import InputText from "../../../components/forms/InputText.vue";
import LoginSocial from "../../../components/ui/login/LoginSocial.vue";

import LoginMixin from "../../mixins/login";

import CustomizableIframe from "../mixins/CustomizableIframe";

export default {
  name: "LoginModal",
  components: {
    bbButton,
    InputText,
    LoginSocial,
  },
  mixins: [
    LoginMixin,
  ],
  data() {
    return {
      password: "",
      passwordError: "",
      email: "",
      emailError: "",
      loading: false,
      accessToken: "",
      userID: "",
      valid: true,
      msg: "",
      error: null,
    };
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    },
    redirectTo() {
      const wantedRoute = this.$route.query.wantedRoute;
      if (!wantedRoute) {
        return "";
      }

      return {
        to: wantedRoute,
      };
    },
    retailerName() {
      return this.customizations?.displayName || "";
    },
    retailerNameWithFallback() {
      return this.retailerName || "esta tienda";
    },
  },
  methods: {
    async operation() {
      if (this.validations()) {
        const response = await this.login(this.redirectTo, !!this.redirectTo, !!this.redirectTo, "plugin");
        const statusCode = parseInt(response);

        if (statusCode >= 400 || statusCode < 500) {
          this.error = this.$t("login.wrongData", this.lang);
        } else if (statusCode > 500) {
          this.error = this.$t("login.error", this.lang);
        } else {
          this.error = null;
        }

        if (!this.error) {
          this.$emit("loggedIn");
        }
      }
    },
    validations() {
      if (!this.email) {
        this.emailError = this.$t("generic.field_is_required", this.lang);
      } else if (!this.$utils.validateEmail(this.email)) {
        this.emailError = this.$t("login.invalidEmailFormat", this.lang);
      }

      if (!this.password) {
        this.passwordError = this.$t("generic.field_is_required", this.lang);
      }

      return (
        !this.emailError &&
        !this.passwordError
      );
    },
    handleLoggedIn() {
      if (this.$route.meta?.iframe) {
        const url = this.redirectTo.to;
        if (this.redirectTo) {
          window.location.href = this.redirectTo.to;
        } else {
          window.location.reload();
        }

        return;
      }

      $emit('loggedIn')
    },
  },
};
</script>
