<template>
  <div id="app" :class="[className, bodySource, classNameInternational]">
    <ConfirmationMessage v-if="true"></ConfirmationMessage>
    <!-- <Landbot></Landbot> -->
    <LazyHydrate when-idle>
      <component v-if="hasHeader" :is="header" :headerLang="headerLang" :headerType="headerType"></component>
    </LazyHydrate>
    <transition name="slide-fade">
      <div id="app_main_content">
        <!--        SERGI TUR -> Llegiu: https://github.com/vuejs/vue-router/issues/977-->
        <component :is="forceShowPage" v-if="forceShowPage"></component>
        <router-view v-else></router-view>
        <!-- IMPORTANT ELIMINAT :key="$route.fullPath" que desactiva Reactive To params changes
          https://v3.router.vuejs.org/guide/essentials/dynamic-matching.html#reacting-to-params-changes
          read https://stackoverflow.com/questions/40404787/best-practice-for-reacting-to-params-changes-with-vue-router
          QUE IMPLICA -> no s'executen els watch $Route
          Millor que tornar a activar, utilitzar opcions vue router docs per reaccionar a canvis en paràmetres
          -->
      </div>
    </transition>
    <component v-if="hasFooter" :is="footer" :footerLang="footerLang"></component>
    <no-ssr>
      <portal-target name="modals"></portal-target>
    </no-ssr>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "./Header.vue";
import Footer from "./Footer.vue";

import HeaderList from "./HeaderList.vue";
import HeaderBlog from "./HeaderBlog.vue";
import HeaderCatalogo from "./HeaderCatalogo.vue";
import HeaderOnboardingGuest from "./HeaderOnboardingGuest.vue";
import FooterList from "./FooterList.vue";
import NoSSR from "vue-no-ssr";
import LazyHydrate from "vue-lazy-hydration";
import NotFoundPage from "../modules/NotFoundPage";
import ConfirmationMessage from "../components/ui/ConfirmationMessage.vue";
import Landbot from "../components/Landbot.vue";
import Features from "../Mixins/Features";
import FullPageModal from "../Mixins/FullPageModal";
import NotAvailableInternationalPage from "../modules/NotAvailableInternationalPage.vue";

export default {
  name: "App",
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Header,
    HeaderBlog,
    HeaderCatalogo,
    HeaderOnboardingGuest,
    // eslint-disable-next-line vue/no-reserved-component-names
    Footer,
    HeaderList,
    FooterList,
    "no-ssr": NoSSR,
    LazyHydrate,
    NotFoundPage,
    ConfirmationMessage,
    Landbot,
    NotAvailableInternationalPage,
  },
  mixins: [
    Features,
    FullPageModal,
  ],
  metaInfo() {
    return {
      htmlAttrs: {
        lang: this.browserLanguageWithoutCountry,
      },
      script: [{ innerHTML: this.pinterestScript }],
    };
  },
  data() {
    return {
      forceShowPage: null,
      pinterestScript: "",
    };
  },
  computed: {
    ...mapGetters({
      browserLanguageWithoutCountry: "browserLanguageWithoutCountry",
      isAuthenticated: "isAuthenticated",
      isInternational: "isInternational",
    }),
    className() {
      return "body-" + this.$route.name;
    },
    bodySource() {
      if (this.$store.state.cookies.sourceApp) return "body-app-product";
      else return "";
    },
    hasHeader() {
      return !(this.$route.meta?.header === false);
    },
    header() {
      return this.$route.meta?.header || "Header";
    },
    hasFooter() {
      return !(this.$route.meta?.footer === false);
    },
    footer() {
      return this.$route.meta?.footer || "Footer";
    },
    footerLang() {
      return this.$route.meta?.footerLang || this.$i18n.locale;
    },
    headerLang() {
      return this.$route.meta?.headerLang || this.$i18n.locale;
    },
    headerType() {
      return this.$route.meta?.headerType || "";
    },
    classNameInternational() {
      // TODO: Use `feature*Available` getters.
      return this.isInternational
        ? "body-international"
        : "";
    },
    pintscript() {
      return new DOMParser().parseFromString(
        "!function(e){if(!window.pintrk){window.pintrk = function () {" +
          "window.pintrk.queue.push(Array.prototype.slice.call(arguments))};var " +
          'n=window.pintrk;n.queue=[],n.version="3.0";var ' +
          't=document.createElement("script");t.async=!0,t.src=e;var ' +
          'r=document.getElementsByTagName("script")[0];' +
          'r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");' +
          'pintrk("load", "2613200850153", {em: "' +
          this.$store.state.cookies.email +
          '"});' +
          'pintrk("page");',
        "text/html"
      );
    },
  },
  async created() {
    if (this.$clientSide()) {
      const forceShowPageMeta = document.querySelector('meta[name="forceShowPage"]');
      if (forceShowPageMeta) {
        this.forceShowPage = forceShowPageMeta.content;
      }
    }
  },
  mounted() {
    document.addEventListener("gesturestart", function (e) {
      e.preventDefault();
    });
    if (this.$route.query.source === "app")
      document.cookie = "cookie_consent_user_accepted=true; SameSite=Strict; Secure";
    if (
      this.$store.state.cookies.sourceApp === "true" &&
      process.env.NODE_ENV === "production"
    )
      hj("event", "session-app");
  },
  async beforeMount() {
    // Required for `isInternational` getter.
    //await this.$store.dispatch("prefetchCountry");
  },
};
</script>
