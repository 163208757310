<template>
  <aside class="promotion-banner">
    <div class="promotion-banner__cover">
      <img :src="promotion.logo" alt="" />
    </div>

    <div class="promotion-banner__content">
      <div class="promotion-banner__badge">
        <i class="uil uil-award"></i>
      </div>
      <div class="promotion-banner__title" v-if="false">
        {{ promotion.name }}
      </div>
      <div class="promotion-banner__description">
        {{ promotion.description }}
      </div>
      <div class="promotion-banner__actions">
        <button class="button button--accent" @click.prevent="showDialog = true">
          {{ $t("generic.viewMore") }}
        </button>
      </div>
    </div>

    <ModalDialog
      :show="showDialog"
      @close="showDialog = false"
      target-class="modal__card--mini"
    >
      <PromotionModalContent :promotion="promotion" />
    </ModalDialog>
  </aside>
</template>

<script>
import { mapGetters } from "vuex";

import ModalDialog from "../../components/ui/ModalDialog";

import PromotionModalContent from "./components/PromotionModalContent";

export default {
  name: "Promotion",
  components: {
    ModalDialog,
    PromotionModalContent,
  },
  props: {
    promotion: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
    };
  },
};
</script>
