var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bb-form-item"},[(_vm.label)?_c('div',{staticClass:"bb-form-label",attrs:{"for":_vm.id,"aria-label":_vm.label}},[_vm._v("\n    "+_vm._s(_vm.label)),(_vm.required)?[_vm._v("*")]:_vm._e()],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"bb-form__input",class:[
      _vm.icoStart || _vm.icoEnd || _vm.type == 'password' || _vm.$slots.logo ? 'bb-form-ico' : '' ]},[(_vm.type == 'password')?[((_vm.showPassword ? 'text' : 'password')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],staticClass:"bb-form bb-form-icoEnd",class:[
          (_vm.error || _vm.errorLabel) ? 'invalid' : '' ],attrs:{"id":_vm.id,"name":_vm.id,"maxlength":_vm.maxlength,"required":_vm.required,"placeholder":_vm.placeholder,"aria-placeholder":_vm.placeholder,"autocomplete":_vm.autocomplete,"inputmode":_vm.inputmode,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.inputValue)?_vm._i(_vm.inputValue,null)>-1:(_vm.inputValue)},on:{"input":_vm.handleInput,"focus":function($event){return _vm.$emit('focus')},"blur":function($event){return _vm.$emit('blur')},"change":function($event){var $$a=_vm.inputValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.inputValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.inputValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.inputValue=$$c}}}}):((_vm.showPassword ? 'text' : 'password')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],staticClass:"bb-form bb-form-icoEnd",class:[
          (_vm.error || _vm.errorLabel) ? 'invalid' : '' ],attrs:{"id":_vm.id,"name":_vm.id,"maxlength":_vm.maxlength,"required":_vm.required,"placeholder":_vm.placeholder,"aria-placeholder":_vm.placeholder,"autocomplete":_vm.autocomplete,"inputmode":_vm.inputmode,"type":"radio"},domProps:{"checked":_vm._q(_vm.inputValue,null)},on:{"input":_vm.handleInput,"focus":function($event){return _vm.$emit('focus')},"blur":function($event){return _vm.$emit('blur')},"change":function($event){_vm.inputValue=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],staticClass:"bb-form bb-form-icoEnd",class:[
          (_vm.error || _vm.errorLabel) ? 'invalid' : '' ],attrs:{"id":_vm.id,"name":_vm.id,"maxlength":_vm.maxlength,"required":_vm.required,"placeholder":_vm.placeholder,"aria-placeholder":_vm.placeholder,"autocomplete":_vm.autocomplete,"inputmode":_vm.inputmode,"type":_vm.showPassword ? 'text' : 'password'},domProps:{"value":(_vm.inputValue)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.inputValue=$event.target.value},_vm.handleInput],"focus":function($event){return _vm.$emit('focus')},"blur":function($event){return _vm.$emit('blur')}}}),_vm._v(" "),_c('div',{staticClass:"bb-form-ico__show",on:{"click":_vm.togglePasswordVisibility}},[_c('i',{staticClass:"uil",class:_vm.showPassword ? 'uil-eye-slash' : 'uil-eye'})])]:[(_vm.$slots.logo)?_c('div',{staticClass:"bb-form-ico__logo"},[_vm._t("logo")],2):_vm._e(),_vm._v(" "),(_vm.icoStart)?_c('div',{staticClass:"bb-form-ico__start"},[_c('i',{staticClass:"uil",class:_vm.icoStart})]):_vm._e(),_vm._v(" "),((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],staticClass:"bb-form",class:[
          _vm.size == 'small' ? 'bb-form--small' : '',
          _vm.icoStart ? 'bb-form-ico--icoStart' : '',
          _vm.icoEnd ? 'bb-form-ico--icoEnd' : '',
          _vm.type == 'password' ? 'bb-form-ico--icoEnd' : '',
          _vm.$slots.logo ? 'bb-form-ico--icoStart' : '',
          (_vm.error || _vm.errorLabel) ? 'invalid' : '' ],attrs:{"id":_vm.id,"name":_vm.id,"maxlength":_vm.maxlength,"required":_vm.required,"placeholder":_vm.placeholder,"aria-placeholder":_vm.placeholder,"autocomplete":_vm.autocomplete,"disabled":_vm.disabled,"inputmode":_vm.inputmode,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.inputValue)?_vm._i(_vm.inputValue,null)>-1:(_vm.inputValue)},on:{"input":_vm.handleInput,"focus":function($event){return _vm.$emit('focus')},"blur":function($event){return _vm.$emit('blur')},"change":function($event){var $$a=_vm.inputValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.inputValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.inputValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.inputValue=$$c}}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],staticClass:"bb-form",class:[
          _vm.size == 'small' ? 'bb-form--small' : '',
          _vm.icoStart ? 'bb-form-ico--icoStart' : '',
          _vm.icoEnd ? 'bb-form-ico--icoEnd' : '',
          _vm.type == 'password' ? 'bb-form-ico--icoEnd' : '',
          _vm.$slots.logo ? 'bb-form-ico--icoStart' : '',
          (_vm.error || _vm.errorLabel) ? 'invalid' : '' ],attrs:{"id":_vm.id,"name":_vm.id,"maxlength":_vm.maxlength,"required":_vm.required,"placeholder":_vm.placeholder,"aria-placeholder":_vm.placeholder,"autocomplete":_vm.autocomplete,"disabled":_vm.disabled,"inputmode":_vm.inputmode,"type":"radio"},domProps:{"checked":_vm._q(_vm.inputValue,null)},on:{"input":_vm.handleInput,"focus":function($event){return _vm.$emit('focus')},"blur":function($event){return _vm.$emit('blur')},"change":function($event){_vm.inputValue=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],staticClass:"bb-form",class:[
          _vm.size == 'small' ? 'bb-form--small' : '',
          _vm.icoStart ? 'bb-form-ico--icoStart' : '',
          _vm.icoEnd ? 'bb-form-ico--icoEnd' : '',
          _vm.type == 'password' ? 'bb-form-ico--icoEnd' : '',
          _vm.$slots.logo ? 'bb-form-ico--icoStart' : '',
          (_vm.error || _vm.errorLabel) ? 'invalid' : '' ],attrs:{"id":_vm.id,"name":_vm.id,"maxlength":_vm.maxlength,"required":_vm.required,"placeholder":_vm.placeholder,"aria-placeholder":_vm.placeholder,"autocomplete":_vm.autocomplete,"disabled":_vm.disabled,"inputmode":_vm.inputmode,"type":_vm.type},domProps:{"value":(_vm.inputValue)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.inputValue=$event.target.value},_vm.handleInput],"focus":function($event){return _vm.$emit('focus')},"blur":function($event){return _vm.$emit('blur')}}}),_vm._v(" "),(_vm.icoEnd)?_c('div',{staticClass:"bb-form-ico__end"},[_c('i',{staticClass:"uil",class:_vm.icoEnd})]):_vm._e()]],2),_vm._v(" "),(_vm.error || _vm.errorLabel)?_c('div',{staticClass:"bb-form-error"},[(_vm.errorLabel)?[_vm._v(_vm._s(_vm.errorLabel))]:[_vm._v("Lo sentimos, el campo es obligatorio")]],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }