<template>
  <div class="ml-filters__filter ml-filters__filter--search">
    <input
      type="search"
      class="bb-form bb-form--search bb-form--sm"
      :placeholder='$t("generic.searchInYourList")'
      @input="debounceSearch"
      v-model="inputValue"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "SearchFilter",
  data() {
    return {
      textTosearch: null,
      debounce: null,
      inputValue: "",
    };
  },
  computed: {
    ...mapGetters({
      ownerProductFilters: "ownerProductFilters",
    }),
  },
  methods: {
    debounceSearch(event) {
      this.textTosearch = null;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.textTosearch = event.target.value;
        this.applyFilter();
      }, 600);
    },
    applyFilter() {
      let newFilters = [...this.ownerProductFilters];
      const found = newFilters.find((filter) => filter.name === "search");
      if (found) {
        newFilters.splice(newFilters.indexOf(found), 1);
      }
      if (this.textTosearch !== "") {
        newFilters.push({
          name: "search",
          values: {
            textTosearch: this.textTosearch,
          },
        });
      }
      this.$setOwnerProductFilters(newFilters, false);
    },
    setTextToSearchFromCurrentFilters() {
      const found = this.ownerProductFilters.find((filter) => filter.name === "search");

      this.textTosearch = found?.values?.textTosearch || "";
      this.inputValue = this.textTosearch;
    },
    init() {
      this.textTosearch = null;
      this.setTextToSearchFromCurrentFilters();
    },
  },
  watch: {
    ownerProductFilters: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.init();
      }
    },
  },
};
</script>
