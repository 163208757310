<template>
  <a @click="showModal = true">
    <button class="button button--red button--sm button--ico">
      <i class="uil uil-trash-alt"></i>
      {{ $t("productPage.removeFromList") }}
    </button>
    <modal-dialog :show="showModal" @close="showModal = false" target-class="modal__card--mini">
      <div class="card-wizard">
        <div class="card-wizard__title">
          <h3 class="header-3 w-70">
            {{ title }}
          </h3>
          <p v-if="subtitle" v-text="subtitle"></p>
        </div>
        <div class="card-wizard__actions card-wizard__actions--mt-0">
          <loading-button v-if="loading"></loading-button>
          <button v-else class="button button--primary button--block" @click="remove()">
            {{ $t("generic.remove") }}
          </button>
          <button
            class="button button--secondary mar-t-fs-smaller button--block"
            @click="showModal = false"
          >
            {{ $t("generic.cancel") }}
          </button>
        </div>
      </div>
    </modal-dialog>
  </a>
</template>

<script>
import ModalDialog from "../../../components/ui/ModalDialog";
import LoadingButton from "../../../components/LoadingButton";
import ProductDelete from "./Mixins/ProductDelete.js";
import { mapGetters } from "vuex";

export default {
  name: "EditProductDelete",
  components: {
    "modal-dialog": ModalDialog,
    "loading-button": LoadingButton,
  },
  props: {
    listId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      product: "ownerListProduct",
    }),
  },
  mixins: [ProductDelete],
  methods: {
    async remove() {
      this.loading = true;
      if(!this.product.isFree) await this.$store.dispatch("removeListProductByListId", { listId: this.listId, productId: this.product.listProductId });
      else await this.$store.dispatch("removeProductFromList", { listId: this.listId, product: this.product });
      //if(this.product.shopifyId) await this.$store.dispatch("removeListProduct", { productId: this.product.listProductId });
      //else await this.$store.dispatch("removeProductFromList", { list: this.list, product: this.product });
      await this.$store.dispatch("getOwnerList");

      this.loading = false;
      this.showModal = false;
      window.localStorage.setItem("alertMessage", this.$t("mylist.removeSuccess"));
      await this.$router.push({ name: "owner-list" });
    },
  },
};
</script>
