<template>
  <div class="bb-login">
    <div class="bb-login__container">
      <div class="login-box">
        <Form class="" :operation="operation" :valid="valid" :error="error">
          <template>
            <div>
              <h1 class="login-box__title">{{ $t("external.signup", lang, { retailer: this.retailerNameWithFallback }) }}</h1>
              <h2 class="login-box__description">{{ $t("external.signupDescription", lang, { retailer: this.retailerNameWithFallback }) }}</h2>
            </div>
            <div class="login-box__inputs">
              <InputText
                :errorLabel="usernameError"
                :label="$t('signup.yourName', lang) + ':'"
                :placeholder="$t('signup.yourName', lang)"
                @focus="usernameError = ''"
                id="username"
                v-model="username"
              />
              <InputText
                :errorLabel="emailError"
                :label="$t('login.yourEmail', lang) + ':'"
                :placeholder="$t('login.yourEmail', lang)"
                @focus="emailError = ''"
                id="email"
                v-model="email"
              />
              <InputText
                :errorLabel="passwordError"
                :label="$t('login.password', lang)"
                :placeholder="$t('login.password', lang)"
                @focus="passwordError=''"
                name="password"
                type="password"
                v-model="password"
              />
              <label class="w-checkbox checkbox-field">
                <input
                  type="checkbox"
                  id="Privacidad"
                  name="Privacidad"
                  data-name="Privacidad"
                  class="w-checkbox-input"
                  v-model="privacity"
                  @change="privacityError = ''"
                />
                <label style="display: inline;" for="Privacidad" class="checkbox-label w-form-label">
                  &nbsp;{{ $t("signup.acceptTCpart1", lang) }}
                  <router-link :to="$t('routes.confidentialityPolicy', lang)" style="text-decoration: underline;" target="_blank"
                  >{{ $t("signup.acceptTCpart2", lang) }}
                  </router-link
                  >
                  .
                </label>
              </label>
              <div v-if="privacityError" class="bb-form-error">
                {{ privacityError }}
              </div>
            </div>
          </template>
          <template v-slot:actions>
            <div class="login-box__actions">
              <input
                type="submit"
                :disabled="!valid"
                :value="$t('signup.cta', lang)"
                data-wait="Un segundo..."
                class="button button--primary button--block button--lg"
              />
            </div>
          </template>
        </Form>

        <login-social
          :show="true"
          :showFacebook="false"
          :showLabel="true"
          :wantedRoute="$route.query.wantedRoute || ''"
          class="login-box__social"
          color="white"
          size="large"
          origin="plugin"
          :forcedLang="lang"
          @signedUp="$emit('signedUp')"
          @loggedIn="handleLoggedIn()"
          @popup="$emit('popup')"
          :retailerName="retailerName"
          verticalAlign="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

import InputText from "../../../components/forms/InputText.vue";
import LoginSocial from "../../../components/ui/login/LoginSocial.vue";

import loginMixin from "../../mixins/login.js";

import CustomizableIframe from "../mixins/CustomizableIframe";

const crypto = require("crypto");

export default {
  name: "SignupModal",
  components: { InputText, LoginSocial },
  mixins: [
    loginMixin,
    CustomizableIframe,
  ],
  data() {
    return {
      username: "",
      usernameError: "",
      password: "",
      passwordError: "",
      email: "",
      emailError: "",
      loading: false,
      accessToken: "",
      userID: "",
      privacity: false,
      privacityError: "",
      valid: true,
      msg: "",
      error: null,
    };
  },
  computed: {
    lang() {
      return this.$route.meta?.lang || this.$i18n.locale;
    },
    redirectTo() {
      const wantedRoute = this.$route.query.wantedRoute;
      if (!wantedRoute) {
        return "";
      }

      return {
        to: wantedRoute,
      };
    },
    retailerName() {
      return this.customizations?.displayName || "";
    },
    retailerNameWithFallback() {
      return this.retailerName || "esta tienda";
    },
  },
  methods: {
    async operation() {
      if (this.validations()) {
        await this.signUp();
      }
    },
    validations() {
      if (!this.username) {
        this.usernameError = this.$t("generic.field_is_required", this.lang);
      }

      if (!this.password) {
        this.passwordError = this.$t("generic.field_is_required", this.lang);
      }

      if (!this.email) {
        this.emailError = this.$t("generic.field_is_required", this.lang);
      } else if (!this.$utils.validateEmail(this.email)) {
        this.emailError = this.$t("login.invalidEmailFormat", this.lang);
      }

      if (!this.privacity) {
        this.privacityError = this.$t("signup.mustAcceptTermsAndConditions", this.lang);
      }

      return (
        !this.usernameError &&
        !this.passwordError &&
        !this.emailError &&
        !this.privacityError
      );
    },
    async signUp() {
      const baseURL = process.env.API;
      try {
        await axios.post(baseURL + "/user/signup", {
            email: this.email,
            password: this.password,
            name: this.username,
            signupOrigin: "plugin",
            retailer: this.retailerName,
          }
        );
        this.setModalShownCookie();

      } catch (error) {
        if (parseInt(error.response?.status) === 409) {
          this.error = this.$t("signup.errorEmailInUse", this.lang);
          this.$sleep(() => { this.$emit("goToLogin"); }, 2000);
          return;
        }
      }

      //window.mixpanel.people.set({ Skipped: false });
      window.mixpanel.people.set("Signup Origin", "plugin");
      this.$root.trackConversion({
        email: this.email,
      })

      await this.login("", false);

      this.$emit("signedUp");

      if (this.redirectTo) {
        window.location.href = "/onboarding-1?modal=1&newuser=true&wantedRoute=" + encodeURIComponent(this.redirectTo.to);
      }
    },
    setModalShownCookie() {
      const days = 30;
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + days);

      const currentTimestamp = new Date().getTime().toString();
      const randomValue = Math.random().toString();
      const uniqueHash = crypto.createHash("sha256").update(currentTimestamp + randomValue).digest("hex");

      document.cookie = `modalShown=${uniqueHash}; expires=${expirationDate.toUTCString()}; path=/`;

      // Acceder a la cookie y mostrar su valor en la consola
      const cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)modalShown\s*=\s*([^;]*).*$)|^.*$/, "$1");
      console.log('Valor de la cookie modalShown:', cookieValue);
    },
    handleLoggedIn() {
      if (this.$route.meta?.iframe) {
        const url = this.redirectTo.to;
        if (this.redirectTo) {
          window.location.href = this.redirectTo.to;
        } else {
          window.location.reload();
        }

        return;
      }

      $emit('loggedIn')
    },
  }
};
</script>
