<template>
  <div>
    <div class="bb-container bb-container--lista">
      <portal-target name="alerts_ownerlist"></portal-target>
    </div>

    <div class="bg-cercles">
      <list-wizard v-if="showListWizard && !isApp"></list-wizard>

      <!-- TODO: Remove the hardcoded `v-if="false"` when ecommerce gets more priority. -->
      <div v-if="false" class="bb-container bb-container--lista" style="padding-top: 30px">
        <div>
          <CtaMainBannerSm parentPage="mi-lista" v-if="showListWizard && featureEcommerceAvailable && (ownerListType === 'baby-registry' || ownerListType === 'babyshower')"></CtaMainBannerSm>
        </div>
      </div>

      <div v-if="listAvailable">
        <list-header :showSummary="showListWizard" />
        <!-- <list-header-no-multi-list v-else :showSummary="showListWizard" /> -->
        <funds></funds>
        <list></list>
      </div>
      <div v-else>
        <!-- LIST SKELETON/LOADING HERE TODO -->
      </div>
      <LazyHydrate when-visible>
        <cta-contact></cta-contact>
      </LazyHydrate>
    </div>

    <transition name="bbFace" @after-enter="showSubscribedModalContent = true">
      <div class="modal" v-show="showSubscribedModal" @click="showContent = false">
        <transition name="bbUp" @after-leave="closeSubscribedModal()">
          <div
            class="modal__card modal__card--mini"
            v-show="showSubscribedModalContent"
            @click.stop=""
          >
            <div class="modal__dialog">
              <div class="modal__header">
                <div class="modal__close">
                  <img
                    src="../../assets/img/ico/ico-close.svg"
                    alt="Cerrar"
                    @click="showSubscribedModalContent = false"
                  />
                </div>
              </div>
              <div class="modal__content">
                <div class="card-success">
                  <div class="card-success__ico">
                    <img
                      src="../../assets/img/ico/ico-color-success.svg"
                      alt="¡Gracias!"
                    />
                  </div>
                  <h3 class="card-success__header">Pago recibido</h3>
                  <div class="card-success__content">
                    Ya puedes disfrutar de HelloBB sin publicidad.
                  </div>
                  <div class="card-success__actions">
                    <a
                      href="#"
                      @click="showSubscribedModalContent = false"
                      class="button button--primary button--w60"
                      >Genial</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ListHeader from "./components/ListHeader";
import ListHeaderNoMultiList from "./components/ListHeaderNoMultiList";
import List from "./components/List";
import Funds from "./components/Funds";
import NoSSR from "vue-no-ssr";
import CanGetOwnerList from "../../Mixins/CanGetOwnerList";
import LazyHydrate from "vue-lazy-hydration";
import CtaBannerFixed from "../cta/CtaBannerFixed.vue";
import CtaMainBannerSm from "../constructor/components/CtaMainBannerSm.vue";
import Features from "../../Mixins/Features";
// import Cookies from "js-cookie";

export default {
  name: "OwnerListPage",
  mixins: [
    CanGetOwnerList,
    Features,
  ],
  components: {
    ListWizard: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "ListWizard" */ "./components/ListWizard"
        ),
    "list-header": ListHeader,
    "list-header-no-multi-list": ListHeaderNoMultiList,
    list: List,
    funds: Funds,
    CtaContact: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "CtaContact" */ "./components/CtaContact"
        ),
    "no-ssr": NoSSR,
    // eslint-disable-next-line vue/no-unused-components
    BannerPlaceholder: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "BannerPlaceholder" */ "./banners/BannerPlaceholder"
        ),
    // eslint-disable-next-line vue/no-unused-components
    BannerBuit: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "BannerBuit" */ "./banners/BannerBuit"
        ),
    // eslint-disable-next-line vue/no-unused-components
    Banner001MasAnadido: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Banner001MasAnadido" */ "./banners/Banner001MasAnadido"
        ),
    // eslint-disable-next-line vue/no-unused-components
    Banner002Portear: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Banner002Portear" */ "./banners/Banner002Portear"
        ),
    // eslint-disable-next-line vue/no-unused-components
    Banner003Arrullando: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Banner003Arrullando" */ "./banners/Banner003Arrullando"
        ),
    // eslint-disable-next-line vue/no-unused-components
    Banner004Sillas: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Banner004Sillas" */ "./banners/Banner004Sillas"
        ),
    // eslint-disable-next-line vue/no-unused-components
    Banner005ObjetosCalidad: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Banner005ObjetosCalidad" */ "./banners/Banner005ObjetosCalidad"
        ),
    // eslint-disable-next-line vue/no-unused-components
    Banner006PrimerosCuidados: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Banner006PrimerosCuidados" */ "./banners/Banner006PrimerosCuidados"
        ),
    // eslint-disable-next-line vue/no-unused-components
    Banner007BolsasPanaleras: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Banner007BolsasPanaleras" */ "./banners/Banner007BolsasPanaleras"
        ),
    // eslint-disable-next-line vue/no-unused-components
    Banner008PrimerosDulcesSuenos: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Banner008PrimerosDulcesSuenos" */ "./banners/Banner008PrimerosDulcesSuenos"
        ),
    // eslint-disable-next-line vue/no-unused-components
    Banner009ChupetesPararTren: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Banner009ChupetesPararTren" */ "./banners/Banner009ChupetesPararTren"
        ),
    LazyHydrate,
    // eslint-disable-next-line vue/no-unused-components
    CtaBannerFixed,
    CtaMainBannerSm,
  },
  metaInfo() {
    return {
      title: this.title + " | HelloBB",
      meta: [{ name: "robots", content: "noindex" }],
    };
  },
  data() {
    return {
      isCtaFixedBannerVisible: false,
      showSubscribedModal: false,
      showSubscribedModalContent: false,
    };
  },
  computed: {
    ...mapGetters({
      limit: "limit",
      user: "user",
      ownerListType: "ownerListType",
    }),
    showListWizard() {
      if (this.list != null) {
        if (this.list.products != null) {
          if (this.list.products.length > 0) return true;
        }
      }
      return false;
    },
    title() {
      if (this.list.title) return this.list.title;
      if (this.list.ownerName) return "La lista de " + this.list.ownerName;
      return "Lista sín título";
    },
    description() {
      return this.title;
    },
    isApp() {
      return this.$store.state.cookies.sourceApp;
    },
  },
  methods: {
    ctaFixedBannerClosed() {
      this.isCtaFixedBannerVisible = false;
    },
    trackClick() {
      alert(this.$refs.ctaMainBanner.Name);
    },

    async openSubscribedModal(checkoutSessionId) {
      const {
        paymentStatus,
        type,
      } = await this.$store.dispatch("getCheckoutSessionId", {
        checkoutSessionId,
      });

      const isValidCheckoutSessionId = (
        type === "upgrade" &&
        paymentStatus === "paid"
      );
      if (!isValidCheckoutSessionId) {
        console.error("Invalid checkout session ID.");

        this.$router.replace({ path: "/mi-lista" });

        return;
      }

      await this.$store.dispatch("updateUserRoleInSession", "Premium");

      document.body.classList.add("modal-open");
      this.showSubscribedModal = true;
    },
    closeSubscribedModal() {
      document.body.classList.remove("modal-open");
      this.showSubscribedModal = false;

      // This removes the query param, so if the user refreshes the page, the
      // modal is not shown again.
      this.$router.replace({ path: "/mi-lista" });
    },
  },
  created() {
    const filtersQS = this.$route.query.filters;
    if (typeof filtersQS === "string" || filtersQS instanceof String) {
      const filters = JSON.parse(filtersQS);
      this.$setOwnerProductFilters(filters, false);
    } else {
      this.$setOwnerProductFilters([], false, false);
    }
    this.ctaBySections = {
      4: "BannerBuit",
      5: "Banner001MasAnadido",
      6: "Banner002Portear",
      7: "Banner003Arrullando",
      8: "Banner004Sillas",
      9: "Banner005ObjetosCalidad",
      10: "Banner006PrimerosCuidados",
      11: "Banner007BolsasPanaleras",
      12: "Banner008PrimerosDulcesSuenos",
    };
  },
  async beforeMount() {
    await this.$store.dispatch("getPromotions", this.ownerListType);
  },
  async mounted() {
    await this.$store.dispatch("setLimitSectionsToShow");
    this.isCtaFixedBannerVisible =
      window.localStorage.getItem("cta_fixed_banner_" + this.limit) === null;
    window.vm = {};
    vm.someComponent = this;

    const checkoutSessionId = this.$route.query?.session_id;
    if (checkoutSessionId) {
      this.$nextTick(async () => {
        await this.openSubscribedModal(checkoutSessionId);
      });
    }
  },
};
</script>
