<template>
  <section class="header-lang">
    <div class="header-lang__flag header-lang__flag--current" @click="openLanguageSelector()">
      <img v-if="currentFlagImageUrl" :src="currentFlagImageUrl" :alt="currentFlagImageAlt" />
      <i class="uil uil-angle-down"></i>
    </div>
    <transition name="bbUpMin">
      <aside
        class="header-lang__dropdown"
        v-if="showLanguageSelector"
        v-click-outside="config"
      >
        <div class="header-lang__flags">
          <div class="header-lang__flag" @click="selectLanguage('es-ES')" v-show="currentFlag !== 'es'">
            <img :src="flags.es" :alt="$t('languageSelector.alternativeText.es')" />
            <span class="header-lang__language">Castellano</span>
          </div>
          <div class="header-lang__flag" @click="selectLanguage('fr-FR')" v-show="currentFlag !== 'fr'">
            <img :src="flags.fr" :alt="$t('languageSelector.alternativeText.fr')" />
            <span class="header-lang__language">Français</span>
          </div>
          <div class="header-lang__flag" @click="selectLanguage('it-IT')" v-show="currentFlag !== 'it'">
            <img :src="flags.it" :alt="$t('languageSelector.alternativeText.it')" />
            <span class="header-lang__language">Italiano</span>
          </div>
        </div>
      </aside>
    </transition>
  </section>
</template>

<script>
import vClickOutside from "v-click-outside";
import { mapGetters } from "vuex";

// Update the `FLAGS` object when changing these imports.
import flagEs from "../../assets/img/ico/ico-bandera-es.svg";
import flagFr from "../../assets/img/ico/ico-bandera-fr.svg";
import flagIt from "../../assets/img/ico/ico-bandera-it.svg";

const FLAGS = {
  es: flagEs,
  fr: flagFr,
  it: flagIt,
};

function is_server() {
  return !(typeof window != "undefined" && window.document);
}

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "SelectorIdioma",
  components: {
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      config: {
        handler: this.closeLanguageSelector,
      },
      flags: FLAGS,
    };
  },
  computed: {
    ...mapGetters({
      toggle: "toggleLanguageSelector",
      myList: "myList",
      myListLanguage: "myListLanguage",
    }),
    showLanguageSelector() {
      return this.toggle;
    },
    currentFlagImageUrl() {
      return this.getLanguageFlagUrl(this.myListLanguage || "es-ES");
    },
    currentFlagImageAlt() {
      return this.getLanguageFlagAltText(this.myListLanguage || "es-ES");
    },
    currentFlag() {
      //return this.getLanguageFlagCode(this.myListLanguage);
      if(this.myListLanguage === 'es-ES') return 'es-ES';
      else if(this.myListLanguage === 'fr') return 'fr-FR';
      else if(this.myListLanguage === 'it') return 'it-IT';
      else return 'es-ES';
    },
  },
  watch: {
    toggle(newValue) {
      newValue ? this.disableBodyScroll() : this.enableBodyScroll()
    },
  },
  methods: {
    openLanguageSelector() {
      this.$store.commit("setToggleLanguageSelector", true);
    },
    closeLanguageSelector() {
      this.$store.commit("setToggleLanguageSelector", false);
    },
    disableBodyScroll() {
      if (!is_server()) {
        document.body.classList.add("language-selector-open");
      }
    },
    enableBodyScroll() {
      if (!is_server()) {
        document.body.classList.remove("language-selector-open");
      }
    },
    async selectLanguage(language) {
      try {
        if (this.myListLanguage === language) {
          return;
        }
        if (typeof language !== "string") {
          throw new Error(`language must be string (got ${typeof language})`);
        }

        await this.$store.dispatch("setLanguage", { language });

        this.$nextTick(() => {
          this.$root.$i18n.locale = language;
        });
      } finally {
        this.closeLanguageSelector();
      }
    },
    getLanguageWithoutCountry(languageCode) {
      return languageCode.split("-")[0];
    },

    // For `"es-ES"`, return `["es-ES", "es"]`.
    //
    // For `"es"`, return `["es"]`
    getLanguageVariants(languageCode) {
      languageCode = languageCode || "";

      return [
        languageCode,
        this.getLanguageWithoutCountry(languageCode),
      ].filter((value, i, arr) => arr.indexOf(value) === i);
    },

    getLanguageFlagAltText(languageCode) {
      languageCode = languageCode || "";

      return this.getLanguageVariants(languageCode)
        .map(key => this.$t(`languageSelector.alternativeText.${key}`))
        .reduce((a, b) => a || b, "");
    },
    getLanguageFlagCode(languageCode) {
      languageCode = languageCode || "";

      return this.getLanguageVariants(languageCode)
        .filter(key => !!FLAGS[key])
        .reduce((a, b) => a || b, "");
    },
    getLanguageFlagUrl(languageCode) {
      languageCode = languageCode || "";

      const key = this.getLanguageFlagCode(languageCode);

      return this.flags[key];
    },
  },
  beforeMount() {
    this.$nextTick(() => {
      this.$root.$i18n.locale = this.myListLanguage;
    });
  },
};
</script>
