import { mapGetters } from "vuex";

export default {

  data() {
    return {
      // These variables are referenced dynamically in the `initializeBanner`
      // and `closeBanner` methods.
      extensionBannerLastClosedTimestamp: 0,
      extensionBannerClosedCounter: 0,
      mobileAppDesktopBannerLastClosedTimestamp: 0,
      mobileAppDesktopBannerClosedCounter: 0,

      bannerClosedUntilPageReload: false,
    };
  },

  computed: {
    ...mapGetters({
      isButtonUser: "isButtonUser",
    }),
    mobileAppDesktopBannerIsAvailable() {
      const maxClosedCount = Number.parseInt(
        process.env.MOBILE_APP_BANNER_HIDE_AFTER_CLOSING_THIS_MANY_TIMES
      );
      const minimumTimeDiffToShowAgain = Number.parseInt(
        process.env.MOBILE_APP_BANNER_HIDE_IF_CLOSED_IN_THE_LAST_X_SECONDS
      );

      if (this.mobileAppDesktopBannerClosedCounter >= maxClosedCount) {
        return false;
      }

      const diff = Date.now() - this.mobileAppDesktopBannerLastClosedTimestamp;

      return diff > minimumTimeDiffToShowAgain * 1000;
    },
    extensionBannerIsAvailable() {
      const maxClosedCount = Number.parseInt(
        process.env.EXTENSION_BANNER_HIDE_AFTER_CLOSING_THIS_MANY_TIMES
      );
      const minimumTimeDiffToShowAgain = Number.parseInt(
        process.env.EXTENSION_BANNER_HIDE_IF_CLOSED_IN_THE_LAST_X_SECONDS
      );

      if (this.isButtonUser) {
        return false;
      }
      if (this.extensionBannerClosedCounter >= maxClosedCount) {
        return false;
      }

      const diff = Date.now() - this.extensionBannerLastClosedTimestamp;

      return diff > minimumTimeDiffToShowAgain * 1000;
    },
    mobileAppDesktopBannerIsVisible() {
      return !this.bannerClosedUntilPageReload && this.mobileAppDesktopBannerIsAvailable;
    },
    extensionBannerIsVisible() {
      return !this.bannerClosedUntilPageReload && !this.mobileAppDesktopBannerIsVisible && this.extensionBannerIsAvailable;
    },
  },

  methods: {
    initializeSessionStorage(key, defaultValue) {
      const rawValue = window.sessionStorage.getItem(key);
      if (rawValue === null && typeof defaultValue !== "undefined") {
        return this.setSessionStorage(key, defaultValue);
      }

      try {
        const value = JSON.parse(rawValue);

        return value;
      } catch (err) {
        console.error(err);

        return null;
      }
    },
    setSessionStorage(key, value) {
      window.sessionStorage.setItem(key, JSON.stringify(value));

      return value;
    },

    closeBanner(prefix) {
      this.bannerClosedUntilPageReload = true;

      this.extensionBannerLastClosedTimestamp = this.setSessionStorage(
        `${prefix}BannerLastClosedTimestamp`,
        Date.now()
      );
      this.extensionBannerClosedCounter = this.setSessionStorage(
        `${prefix}BannerClosedCounter`,
        this[`${prefix}BannerClosedCounter`] + 1
      );
    },

    initializeBanner(prefix) {
      this[`${prefix}BannerLastClosedTimestamp`] = this.initializeSessionStorage(
        `${prefix}BannerLastClosedTimestamp`,
        0
      );
      this[`${prefix}BannerClosedCounter`] = this.initializeSessionStorage(`${prefix}BannerClosedCounter`, 0);
    },

    closeExtensionBanner() {
      return this.closeBanner("extension");
    },
    closeMobileAppDesktopBanner() {
      return this.closeBanner("mobileAppDesktop");
    },
  },

  beforeMount() {
    this.initializeBanner("extension");
    this.initializeBanner("mobileAppDesktop");
  },
}
