<template>
  <div class="h-sticky">
    <no-ssr>
      <div>
        <mq-layout :mq="['m480', 'm576', 'm768', 'm992']">
          <div>
            <AlertAppDownload v-show="isListPage && appDownloadAlertIsAvailable" @close="closeAlert('appDownload')">
              <div v-html="$t('alerts.downloadApp')"></div>
            </AlertAppDownload>

            <AlertHucha v-show="isListPage && piggyBankAlertIsAvailable && !appDownloadAlertIsAvailable" @closeAlertHucha="closeAlertHucha">
              <div>
                <div v-show="$route.path === '/mi-lista'" v-html="$t('mylist.headerEnablePiggybank')"></div>
                <div v-show="$route.path.startsWith('/mi-lista/comprar')" v-html="$t('mylist.headerEnablePiggybankProduct')"></div>
              </div>
            </AlertHucha>
          </div>
        </mq-layout>
      </div>

      <div>
        <mq-layout mq="m1200">
          <AlertHucha
            @closeAlertHucha="closeAlertHucha"
            v-show="isListPage && piggyBankAlertIsAvailable"
          >
            <div>
              <div v-show="$route.path === '/mi-lista'" v-html="$t('mylist.headerEnablePiggybank')"></div>
              <div v-show="$route.path.startsWith('/mi-lista/comprar')" v-html="$t('mylist.headerEnablePiggybankProduct')"></div>
            </div>
          </AlertHucha>
        </mq-layout>
      </div>
    </no-ssr>

    <header-partial :headerLang="headerLang" :headerType="headerType" />
  </div>
</template>

<script>
import NoSSR from "vue-no-ssr";
import AlertHucha from "../components/ui/alerts/AlertHucha.vue";
import AlertAppDownload from "../components/ui/alerts/AlertAppDownload.vue";
import HeaderPartial from "./components/HeaderPartial";
import { mapGetters } from "vuex";
import SessionStorage from "../Mixins/SessionStorage";

export default {
  name: "Header",
  components: {
    HeaderPartial,
    AlertHucha,
    AlertAppDownload,
    "no-ssr": NoSSR,
  },
  mixins: [
    SessionStorage,
  ],
  props: {
    headerLang: {
      type: String,
      default: "es-ES",
    },
    headerType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      show: false,
      closedByTheUser: false,
      alertClosedUntilPageReload: false,
    };
  },
  computed: {
    ...mapGetters({
      contributionsFlag: "contributionsFlag",
      list: "ownerList",
      isAuthenticated: "isAuthenticated",
      hidePiggyBank: "hidePiggyBank",
      isApp: "isApp",
    }),
    isListPage() {
      return this.$route.path.startsWith("/mi-lista");
    },
    appDownloadAlertIsAvailable() {
      if (this.isApp) {
        return false;
      }

      if (this.alertClosedUntilPageReload) {
        return false;
      }

      const maxClosedCount = Number.parseInt(
        process.env.MOBILE_APP_ALERT_HIDE_AFTER_CLOSING_THIS_MANY_TIMES
      );
      const minimumTimeDiffToShowAgain = Number.parseInt(
        process.env.MOBILE_APP_ALERT_HIDE_IF_CLOSED_IN_THE_LAST_X_SECONDS
      );

      if (this.appDownloadAlertClosedCounter >= maxClosedCount) {
        return false;
      }

      const diff = Date.now() - this.appDownloadAlertLastClosedTimestamp;

      return diff > minimumTimeDiffToShowAgain * 1000;
    },
    piggyBankAlertIsAvailable() {
      return !this.alertClosedUntilPageReload && this.show;
    },
  },
  methods: {
    handleScroll() {
      const currentScrollPosition = window.scrollY;

      if (currentScrollPosition > 100) {
        this.show = (
          !this.hidePiggyBank
          && this.contributionsFlag
          && (this.list.stripeAccountId == null  || this.list.stripeAccountId == "")
          && this.list
          && this.list.products
          && this.list.products.length > 0
          && !this.closedByTheUser
        );
      } else {
        this.show = false
      }
    },
    closeAlertHucha() {
      this.show = false;
      this.closedByTheUser = true;
      this.alertClosedUntilPageReload = true;
    },
    setUpAlertHucha() {
      const alertHucha = localStorage.getItem("alertHucha");
      if (alertHucha) {
        const item = JSON.parse(alertHucha);
        const now = new Date();
        if (now.getTime() > item.expiry) {
          localStorage.removeItem("alertHucha");
        } else {
          this.closedByTheUser = item.value === "false";
        }
      }
      if (!this.isAuthenticated) {
        this.show = false;
      }
      else if(Object.keys(this.list).length !== 0)
      {
        this.show = (
          !this.hidePiggyBank
          && this.contributionsFlag
          && (this.list.stripeAccountId == null  || this.list.stripeAccountId == "")
          && this.list
          && this.list.products
          && this.list.products.length > 0
          && window.scrollY > 100
          && !this.closedByTheUser
        );
      }


      window.addEventListener("scroll", this.handleScroll);
    },

    closeAlert(prefix) {
      this.alertClosedUntilPageReload = true;

      this[`${prefix}AlertLastClosedTimestamp`] = this.setSessionStorage(
        `${prefix}AlertLastClosedTimestamp`,
        Date.now()
      );
      this[`${prefix}AlertClosedCounter`] = this.setSessionStorage(
        `${prefix}AlertClosedCounter`,
        this[`${prefix}AlertClosedCounter`] + 1
      );
    },

    initializeAlert(prefix) {
      this[`${prefix}AlertLastClosedTimestamp`] = this.initializeSessionStorage(
        `${prefix}AlertLastClosedTimestamp`,
        0
      );
      this[`${prefix}AlertClosedCounter`] = this.initializeSessionStorage(`${prefix}AlertClosedCounter`, 0);
    },
  },
  beforeMount() {
    this.initializeAlert("appDownload");
  },
  mounted() {
    this.setUpAlertHucha();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  watch: {
    isAuthenticated() {
      if (this.isAuthenticated) {
        this.setUpAlertHucha();
      }
    },
  }
};
</script>
