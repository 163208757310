<template>
  <transition name="slide-fade" class="">
    <div class="ml-filters__filter">
      <div class="bb-filter-item bb-filter-item--inline" id="categories_filter">
        <div class="bb-filter-item__label">{{$t("generic.categories")}}</div>
        <div class="bb-filter" v-click-outside="config">
          <div
            class="bb-filter__select"
            @click="toggle"
            :class="toggleFilter ? 'bb-filter__select--active' : ''"
          >
            {{ label }}
            <img
              src="../../../assets/img/ico/ico-chevron-up.svg"
              loading="lazy"
              alt=""
              width="16px"
              :class="
                toggleFilter
                  ? 'bb-filter__chevron bb-filter__chevron--active'
                  : 'bb-filter__chevron '
              "
            />
          </div>
          <div class="bb-filter__items" v-show="toggleFilter">
            <div
              class="bb-filter__item bb-filter__item--option"
              v-for="(category, index) in categoriesWithAll()"
              :key="index"
              @click="applyFilter(category)"
              :class="category.id === selected ? 'bb-filter__item--active' : ''"
            >
              <span v-text='$t("categories." + category.translationKey)' />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import CanBeClosedByEscKey from "../../../Mixins/CanBeClosedByEscKey";
import CloseOnClickOutside from "../../../components/Mixins/CloseOnClickOutside";
import IsFilter from "./Mixins/IsFilter";
import { mapGetters } from "vuex";

export default {
  name: "CategoryFilter",
  mixins: [CanBeClosedByEscKey, CloseOnClickOutside, IsFilter],
  data() {
    return {
      selected: 99,
    };
  },
  props: {
    onlyCategories: {
      type: Array,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      ownerProductFilters: "ownerProductFilters",
      allCategories: "categories",
    }),
    categories() {
      let result = this.allCategories;

      if (this.onlyCategories && Array.isArray(this.onlyCategories)) {
        result = result.filter(category => this.onlyCategories.indexOf(category.id) >= 0);
      }

      return result;
    },
    label() {
      return this.selected === 99
        ? this.$t("categories.all")
        : this.$t("categories." + this.categories.find((category) => category.id === this.selected)
            ?.translationKey);
    },
  },
  methods: {
    categoriesWithAll() {
      let categoriesWithAll = [...this.categories];
      categoriesWithAll.unshift({ id: 99, translationKey: "all" });
      return categoriesWithAll;
    },
    applyFilter(category) {
      this.selected = category.id;
      let newFilters = [...this.ownerProductFilters];
      const found = newFilters.find((filter) => filter.name === "category");
      if (found) {
        newFilters.splice(newFilters.indexOf(found), 1);
      }
      if (this.selected !== 99) {
        newFilters.push({
          name: "category",
          values: {
            id: category.id,
            slug: category.slug,
            name: category.name
          },
        });
      }
      this.$setOwnerProductFilters(newFilters);
      this.close();
    },
    init() {
      const categoryFound = this.ownerProductFilters.find(
        (filter) => filter.name === "category"
      );
      if (categoryFound) {
        this.selected = categoryFound.values.id;
      } else {
        this.selected = 99;
      }
    },
  },
  watch: {
    ownerProductFilters: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.init();
      }
    },
  },
  async mounted() {
    await this.$store.dispatch("getCategories");
    this.init();
  },
};
</script>
