<template>
  <div :class="targetClass">
    <no-ssr>
      <mq-layout :mq="['m480', 'm576', 'm768']">
        <slot />
      </mq-layout>
    </no-ssr>
  </div>
</template>

<script>
import NoSSR from "vue-no-ssr";

export default {
  name: "ResponsiveMobile",
  components: {
    "no-ssr": NoSSR,
  },
  props: {
    targetClass: {
      default: "",
    },
  },
}
</script>
