<template>
    <header class="ln-hero">
      <modal-dialog
          :show="showDialog"
          @close="showDialog = false"
          target-class="modal__card--indigo-4"
        >
          <div class="content-modal content-modal--left">
            <div class="content-modal__title">Condiciones</div>

            <div class="content-modal__form"></div>
            <div class="content-modal__body">
              <div class="post-entry">
                <p>
                  Para entrar en la promoción, debes hacer tu lista con HelloBB (no solo
                  empezarla 😉). Esto significa:
                </p>
                <ol>
                  <li>
                    <strong>
                      Abrir tu lista y poner al menos 10 cosas en ella (pueden ser de cualquier tienda).
                    </strong>
                  </li>
                  <li>
                    <strong>
                      Compartirla con tus allegados y que te regalen algo de lo que pusiste.
                    </strong>
                  </li>
                  <li>
                    <strong>
                      Ser mayor de edad y residir en España.
                    </strong>
                  </li>
                </ol>
              </div>
            </div>
            <div class="content-modal__actions content-modal__actions--center">
              <bbButton
                class="button--accent"
                label="¡Empezar!"
                @click="dialogAction"
                :loading="loading"
              >
              </bbButton>

              <span
                class="link link--medium link--underline"
                @click="showDialog = false"
              >
                No me interesa
              </span>
            </div>
          </div>
        </modal-dialog>
      <div class="ln-hero__cover" :class="'ln-hero__cover--' + classNameCover">
        <router-link to="/" class="ln-hero__cover-logo">
          <img src="../../../assets/img/logos/logo-hellobb-dark.svg" alt="" />
        </router-link>
        <picture class="ln-hero__inset">
          <slot name="cover"></slot>
        </picture>
      </div>
      <aside class="ln-hero__col scrollto">
        <section class="ln-hero-form">
          <div class="ln-hero-form__logo">
            <img src="../../../assets/img/logos/logo-hellobb-dark.svg" alt="" />
          </div>
          <h1 class="ln-hero-form__title">{{ title }}</h1>
          <h2 class="ln-hero-form__subtitle" v-if="subtitle">
            {{ subtitle }}
          </h2>
          <div class="ln-hero-form__form">
            <!-- <InputText label="Tu Nombre" v-model="username" :errorLabel="usernameError" />
            <InputText label="Tu Email" v-model="email" :errorLabel="emailError" /> -->
            <InputText
              label="Tu Nombre"
              v-model="username"
              :errorLabel="usernameError"
              placeholder="Escribe tu nombre"
              @focus="usernameError = ''"
            />
            <div style="height: 8px"></div>
            <InputText
              label="Tu Email"
              v-model="email"
              :errorLabel="emailError"
              placeholder="Escribe tu email"
              @focus="emailError = ''"
            />
            <div style="height: 8px"></div>
            <InputText
              label="Tu Contraseña"
              v-model="password"
              type="password"
              :errorLabel="passwordError"
              placeholder="Elige una contraseña"
              @focus="passwordError = ''"
            />
            <div style="height: 8px"></div>
            <div class="ln-hero-form__aceptance">
              <label for="aceptance" class="bb-form-checkbox">
                <input
                  type="checkbox"
                  id="aceptance"
                  v-model="privacity"
                  required
                  @change="privacityError = ''"
                />
                <span>
                  Acepto los
                  <a href="/legal" target="_blank" class="link link--bold link--underline">
                    términos y condiciones
                  </a>
                </span>
              </label>
            </div>
            <div v-if="privacityError" class="bb-form-error">
              {{ privacityError }}
            </div>
          </div>
          <div class="ln-hero-form__actions">
            <bbButton
              class="button--accent button--block"
              :label="buttonText"
              @click='openConditionsModal(false, signUp)'
              :loading="loading"
            >
            </bbButton>
          </div>
          <login-social
            :show="true"
            :showFacebook="false"
            :showLabel="true"
            class="login-box__social"
            color="yellow"
            size="large"
            :origin="origin"
            :beforeCallback="openConditionsModalFromSocialButton"
            verticalAlign="true"
          ></login-social>
        </section>
      </aside>
    </header>
  </template>
  
  <script>
  import LoginSocial from "../../../components/ui/login/LoginSocial.vue";
  import InputText from "../../../components/forms/InputText.vue";
  import bbButton from "../../../components/buttons/bbButton.vue";
  import ModalDialog from "../../../components/ui/ModalDialog.vue";
  import axios from "axios";
  
  const crypto = require("crypto");
  import loginMixin from "../../mixins/login.js";
  
  export default {
    name: "LandingHeroSorteo",
    mixins: [loginMixin],
    components: {
      LoginSocial,
      InputText,
      bbButton,
      ModalDialog,
    },
    props: {
      title: {
        type: String,
        required: true,
      },
      buttonText: {
        type: String,
        default: "Empieza tu lista ahora",
      },
      subtitle: {
        type: String,
        required: false,
      },
      origin: {
        type: String,
        required: true,
      },
      showLoginSocial: {
        type: Boolean,
        default: false,
      },
      classNameCover: String,
    },
    computed: {
      isFormValid() {
        return (
          this.username &&
          this.email &&
          this.password &&
          this.privacity &&
          this.validateEmail()
        );
      },
    },
    data() {
      return {
        username: "",
        email: "",
        password: "",
        privacity: false,
        loading: false,
        emailError: "",
        usernameError: "",
        passwordError: "",
        privacityError: "",
        showDialog: false,
        dialogAction: null,
      };
    },
    methods: {
      async signUp() {
        this.loading = true;
        this.resetErrors();
  
        const baseURL = process.env.API;
        try {
          const response = await axios.post(`${baseURL}/user/signup`, {
            name: this.username,
            email: this.email,
            password: this.password,
            SignupOrigin: this.origin,
          });
  
          this.setModalShownCookie();
        } catch (error) {
          if (parseInt(error.response?.status) === 409) {
            this.loading = false;
  
            this.error = "Este email ya ha sido registrado. ¿Quieres iniciar sesión?";
            await this.$sleep(this.goToLogin, 2000);
  
            return;
          }
        }
  
        window.mixpanel.people.set("Signup Origin", this.origin);
        this.$root.trackConversion({
          email: this.email,
        })
  
        await this.login("../onboarding-1");
      },
      validateEmail() {
        const email = this.email.toLowerCase();
        const parts = email.split("@");
        if (parts.length !== 2) {
          return false;
        }
  
        const [left, right] = parts;
  
        return (
          left.match(/^\w+([.-_+]\w+)*$/) &&
          right.match(/^\w+([.-]\w+)*\.\w{2,10}$/)
        );
      },
      setModalShownCookie() {
        const days = 30;
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + days);
  
        const currentTimestamp = new Date().getTime().toString();
        const randomValue = Math.random().toString();
        const uniqueHash = crypto
          .createHash("sha256")
          .update(currentTimestamp + randomValue)
          .digest("hex");
  
        document.cookie = `modalShown=${uniqueHash}; expires=${expirationDate.toUTCString()}; path=/`;
  
        // Acceder a la cookie y mostrar su valor en la consola
        const cookieValue = document.cookie.replace(
          /(?:(?:^|.*;\s*)modalShown\s*=\s*([^;]*).*$)|^.*$/,
          "$1"
        );
      },
      async goToLogin() {
        await this.$router.push({ name: "login" });
      },
      resetErrors() {
        this.usernameError = "";
        this.emailError = "";
        this.passwordError = "";
        this.privacityError = "";
      },
      openConditionsModal(isSocialLogin = false, dialogAction = null) {
        this.resetErrors();

        if (!isSocialLogin) {
          console.log("isSocialLogin", isSocialLogin);
          if (!this.username || !this.email || !this.password || !this.privacity) {
            if (!this.username) this.usernameError = "El nombre es obligatorio";
            if (!this.email) this.emailError = "El email es obligatorio";
            if (!this.password) this.passwordError = "La contraseña es obligatoria";
            if (!this.privacity) this.privacityError = "Debe aceptar los términos y condiciones";
            this.loading = false;
          }

          if (!this.isFormValid) {
            return;
          }
          this.dialogAction = null;
        }

        if (dialogAction !== null) {
          if (typeof dialogAction !== "function") {
            throw new Error("dialogAction must be a function");
          }

          this.dialogAction = dialogAction;
        }

        this.showDialog = true;
      },
      openConditionsModalFromSocialButton(callback) {
        this.openConditionsModal(true, callback);
      },
    },
  };
  </script>
  