<template>
  <div class="wrapper-landing">
    <LandingHeroAfiliado
      origin="referral"
      :referralUserId="referralUserId"
      :referralUserName="referralUserName"
      :referralUserListId="referralUserListId"
      :scrollToConditions="scrollToConditions"
      title=""
    ></LandingHeroAfiliado>
    <main class="bg-cercles">
      <section class="bb-container">
        <LandingVideo title="Mira como es tener una lista en HelloBB"></LandingVideo>
        <LandingFeaturesSimple
          title="Prepárate para tu bebé con la ayuda de los tuyos"
          :use-carousel="false"
        >
          <LandingFeaturesSimpleItem>
            <template slot="ico">
              <img src="../../assets/img/ico/ico-color-box.svg" alt="" />
            </template>
            <template slot="title"> Elige cualquier cosa de cualquier tienda </template>
          </LandingFeaturesSimpleItem>
          <LandingFeaturesSimpleItem>
            <template slot="ico">
              <img src="../../assets/img/ico/ico-color-labels.svg" alt="" />
            </template>
            <template slot="title"> Gana un 5% de lo que gastéis </template>
          </LandingFeaturesSimpleItem>
          <LandingFeaturesSimpleItem>
            <template slot="ico">
              <img src="../../assets/img/ico/ico-color-hucha.svg" alt="" />
            </template>
            <template slot="title"> Reúne dinero para tus necesidades </template>
          </LandingFeaturesSimpleItem>
          <LandingFeaturesSimpleItem>
            <template slot="ico">
              <img src="../../assets/img/ico/ico-color-mobile.svg" alt="" />
            </template>
            <template slot="title"> Ten tu lista siempre en tu bolsillo </template>
          </LandingFeaturesSimpleItem>
        </LandingFeaturesSimple>
        <LandingReviews />
        <LandingHablan />
      </section>
    </main>

    <LandingComoCols
      title="¿Cómo funciona?"
      subtitle="Tu lista de nacimiento digital"
      className="bg-indigo-4"
    >
      <LandingComoColsItem>
        <template slot="icono">
          <img src="../../assets/img/ico/ico-max-click.svg" alt="" />
        </template>
        <template slot="titulo">Elige qué quieres</template>
        <template slot="descripcion">
          <p>
            Escoge el objeto que quieras de cualquier tienda online, copia el enlace en
            HelloBB y guárdalo.
          </p>
          <p>
            ¿Necesitas ideas o no sabes bien qué necesita? No hay problema, te
            recomendaremos las mejores cosas.
          </p>
          <p>
            También puedes tener una Hucha para reunir dinero para lo que
            quieras.
          </p>
        </template>
      </LandingComoColsItem>
      <LandingComoColsItem>
        <template slot="icono">
          <img src="../../assets/img/ico/ico-max-share.svg" alt="" />
        </template>
        <template slot="titulo">Comparte tus deseos</template>
        <template slot="descripcion">
          <p>Cuando quieras, comparte tu lista con tus familiares y amigos.</p>
          <p>
            Enviales un enlace para que accedan desde cualquier sitio y sin registrarse a
            una bonita lista personalizada por ti.
          </p>
        </template>
      </LandingComoColsItem>
      <LandingComoColsItem>
        <template slot="icono">
          <img src="../../assets/img/ico/ico-max-gift.svg" alt="" />
        </template>
        <template slot="titulo">Consigue los regalos</template>
        <template slot="descripcion">
          <p>
            Tus allegados podrán escoger el regalo de tu lista que más les guste y
            comprarlo. Te lo podrán mandar a ti o a ellos para dároslo en una visita.
          </p>
          <p>
            Para que nadie más lo compre, podrán marcar en la lista que ya está regalado.
          </p>
        </template>
      </LandingComoColsItem>
    </LandingComoCols>
    <!--
    <LandingSorteoSection>
      <template slot="titulo">Participa en el sorteo de nuestra HelloBB Box</template>
      <template slot="descripcion">
        <p>
          La HelloBB Box es nuestra forma de dar la bienvenida a los nuevos usuarios y a
          sus futuros peques presentándoles nuestras marcas y productos favoritos.
          Sorteamos 10 cada mes.
        </p>
        <p>
          Los regalos que van dentro de la caja son <strong>sorpresa</strong> y varían de
          forma frecuente. Podría haber desde chupetes, baberos, pañales, toallitas,
          gorros y también cupones descuento y cheques regalo. La caja HelloBB Box suele
          estar valorada en unos <strong>50€</strong>.
        </p>
      </template>
      <template slot="imagen">
        <img src="../../assets/img/covers/cover-sorteo.png" alt="" />
      </template>
    </LandingSorteoSection> -->

    <main class="bg-cercles">
      <section class="bb-container" id="conditions">
        <LandingSorteoBases showToTop="true">
          <template slot="titulo">Condiciones</template>
          <template slot="descripcion">
            <p>
              Para entrar en la promoción, debes hacer tu lista con HelloBB (no solo
              empezarla 😉). Esto significa:
            </p>
            <ol>
              <li>
                <strong>
                  Poner al menos 5 cosas que quieres en tu lista (pueden ser de cualquier
                  tienda).
                </strong>
              </li>
              <li>
                <strong>
                  Comprar algo en la tienda de HelloBB por valor de 30€ o más (¡puedes
                  hacer la compra tú o la gente con la que compartas la lista).
                </strong>
              </li>
            </ol>
            <p>Los 10€ de regalo son para gastar en la tienda de HelloBB*</p>
          </template>
        </LandingSorteoBases>
      </section>
    </main>
    <LandingFooter />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import LandingFeaturesSimple from "./components/LandingFeaturesSimple.vue";
import LandingFeaturesSimpleItem from "./components/LandingFeaturesSimpleItem.vue";
import LandingHero from "./components/LandingHero.vue";
import LandingHeroAfiliado from "./components/LandingHeroAfiliado.vue";
import LandingAppRatings from "./components/LandingAppRatings.vue";
import LandingReviews from "./components/LandingReviews.vue";
import LandingHablan from "./components/LandingHablan.vue";
import LandingFooter from "./components/LandingFooter.vue";

import LandingComoList from "./components/LandingComoList.vue";
import LandingComoListItem from "./components/LandingComoListItem.vue";

import LandingComoCols from "./components/LandingComoCols.vue";
import LandingComoColsItem from "./components/LandingComoColsItem.vue";

import LandingSorteoSection from "./components/LandingSorteoSection.vue";
import LandingSorteoBases from "./components/LandingSorteoBases.vue";

import LandingAfiliadoAlert from "./components/LandingAfiliadoAlert";

import LandingVideo from "./components/LandingVideo.vue";

export default {
  name: "LandingAfiliado",
  metaInfo() {
    return {
      title: "Recomendación: la mejor lista de nacimiento",
      meta: [
        {
          name: "description",
          content:
            "Alguien que te quiere te recomienda la mejor lista de nacimiento ¡Crea la tuya y os regalamos 10€ a cada un@!",
        },
        { name: "robots", content: "noindex" },
        {
          property: "og:title",
          content: `¡${this.referralUserName} te recomienda HelloBB para hacer tu lista de nacimiento!`,
        },
        { property: "og:type", content: "article" },
        {
          property: "og:description",
          content:
            "¡Crea tú también tu lista de nacimiento y os regalamos 10€ a cada un@! ✨",
        },
        {
          property: "og:image",
          content: "https://www.hellobb.net/favicon/favicon-96x96.png",
        },
        {
          property: "og:url",
          content:
            process.env.APP_URL.substring(0, process.env.APP_URL.length - 1) +
            this.$route.path,
        },
        { property: "twitter:card", content: "summary_large_image" },
      ],
    };
  },
  components: {
    LandingAppRatings,
    LandingHablan,
    LandingReviews,
    LandingFooter,
    LandingHero,
    LandingHeroAfiliado,
    LandingFeaturesSimple,
    LandingFeaturesSimpleItem,
    LandingComoList,
    LandingComoListItem,
    LandingComoCols,
    LandingComoColsItem,
    LandingSorteoSection,
    LandingSorteoBases,
    alert: LandingAfiliadoAlert,
    LandingVideo,
  },
  computed: {
    ...mapGetters({
      myList: "myList",
    }),
    referralUserListId() {
      const referralUserListId = this.$route.query.referralUserListId;
      if (typeof referralUserListId !== "string") {
        console.error("Missing `referralUserListId` query param.");

        return "";
      }

      return referralUserListId;
    },
  },
  data() {
    return {
      referralUserName: "",
      referralUserId: "",
    };
  },
  methods: {
    scrollToConditions() {
      const element = this.$el.querySelector("#conditions");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    async getListFromServer() {
      return await this.$store.dispatch("getListGuest", {
        id: this.referralUserListId,
      });
    },
    updateReferralData() {
      this.referralUserName = this.myList.ownerName || "";
      this.referralUserId = (this.myList.userId || "").toString();
    },
  },
  async serverPrefetch() {
    await this.getListFromServer();

    // Needs to be called here so that the `og:` meta tags for social media
    // previews have the required values in their variables.
    this.updateReferralData();
  },
  beforeMount() {
    // Needs to be called here to properly render in browsers.
    //
    // Otherwise, the variables don't get properly set.
    this.updateReferralData();
  },
};
</script>
