<template>
  <div>
    <!--section class="card-success-otros" v-if="show && showPromotedProducts"-->
    <section class="card-success-otros" v-if="false">
      <div class="card-success-otros__header" v-if="!this.contributionsFlag || (this.myList.stripeAccountId !== null && this.myList.stripeAccountId !== '')">
        <picture class="card-success-otros__ico">
          <img src="../assets/img/ico/ico-cover-add-success.svg" alt="" />
        </picture>
        <h3 class="card-success-otros__title">{{ $t("constructor.productAddedToList") }}</h3>
      </div>
      <div class="card-success-otros__hucha" v-else>
        <div class="card-success-otros__hucha-claim">
          <div class="card-success-otros__hucha-ico">
            <img src="../assets/img/ico/ico-check-green.svg" alt="" />
          </div>
          <div class="card-success-otros__hucha-title">
            {{ $t("constructor.productAddedToList") }}. <br />
            {{ $t("constructor.enablePiggybank") }}
          </div>
        </div>
        <div class="card-success-otros__hucha-actions">
          <bbButton label="Activar mi hucha" to="/mi-hucha/crear" />
        </div>
      </div>
      <aside class="card-success-otros__content" v-if="showPromotedProducts">
        <div class="card-success-otros__head">También te recomendamos esto.</div>

        <div class="sotros__item">
          <div class="sotros__card">
            <div class="sotros__cover">
              <img
                :src="selectedProduct.desktopSrc"
                @click="goToProduct(selectedProduct.id, selectedProduct['brand-slug'])"
                alt=""
              />
            </div>
            <div class="sotros__content">
              <div class="sotros__head">
                <h3 class="sotros__title">
                  {{ selectedProduct.name }}
                </h3>
                <div class="sotros__price" v-if="!selectedProduct.salePrice">
                  <bdi>
                    {{ $utils.formatMoney(selectedProduct.price) }}
                  </bdi>
                </div>

                <div
                  class="sotros__price"
                  :class="selectedProduct.salePrice ? 'sotros__price--sale' : ''"
                  v-else
                >
                  <del>
                    <bdi
                      >{{ selectedProduct.price }}
                      <span class="currecy-symbol">€</span></bdi
                    >
                  </del>
                  <bdi
                    >{{ selectedProduct.salePrice }} <span class="currecy-symbol">€</span>
                  </bdi>
                </div>
              </div>
              <div class="sotros__actions">
                <loading-button v-if="adding" />
                <button
                  v-else
                  class="button button--dark button--block button--ico"
                  @click="addProduct(selectedProduct)"
                  :disabled="isInTheList(selectedProduct.id)"
                >
                  <i
                    :class="isInTheList(selectedProduct.id) ? 'uil' : 'uil uil-plus'"
                  ></i>
                  {{ isInTheList(selectedProduct.id) ? "Añadido" : "Añadir" }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="card-success-otros__slick" v-show="false">
          <div class="sotros">
            <VueSlickCarousel v-bind="settingsCarousel">
              <div class="sotros__item" v-for="slide in slides" :key="slide.id">
                <div class="sotros__card">
                  <div class="sotros__cover">
                    <div class="sotros__badge" v-if="false">
                      <span class="badge badge--primary badge--sm">
                        {{ slide.mk.label }}
                      </span>
                    </div>
                    <div class="sotros__sale" v-if="false">
                      <span>{{ slide.mk.discount }}<sup>%</sup></span>
                    </div>
                    <img
                      :src="slide.desktopSrc"
                      @click="goToProduct(slide.id, slide['brand-slug'])"
                      alt=""
                    />
                  </div>
                  <div class="sotros__content">
                    <div class="sotros__head">
                      <h3 class="sotros__title">
                        {{ slide.name }}
                      </h3>
                      <div class="sotros__price" v-if="!slide.salePrice">
                        <bdi>
                          {{ $utils.formatMoney(slide.price) }}
                        </bdi>
                      </div>

                      <div
                        class="sotros__price"
                        :class="slide.salePrice ? 'sotros__price--sale' : ''"
                        v-else
                      >
                        <del>
                          <bdi
                            >{{ slide.price }} <span class="currecy-symbol">€</span></bdi
                          >
                        </del>
                        <bdi
                          >{{ slide.salePrice }} <span class="currecy-symbol">€</span>
                        </bdi>
                      </div>
                    </div>
                    <div class="sotros__actions">
                      <loading-button v-if="adding" />
                      <button
                        v-else
                        class="button button--dark button--block button--ico"
                        @click="addProduct(slide)"
                        :disabled="isInTheList(slide.id)"
                      >
                        <i :class="isInTheList(slide.id) ? 'uil' : 'uil uil-plus'"></i>
                        {{ isInTheList(slide.id) ? "Añadido" : "Añadir" }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </VueSlickCarousel>
          </div>
        </div>
      </aside>
    </section>

    <div v-else>
      <section v-if="isMiLista" class="card-success">
        <div class="card-success__header">Sigue buscando lo mejor para tu bebé 👶</div>
      </section>
      <section v-else class="card-success">
        <picture class="card-success__ico">
          <img src="../assets/img/ico/ico-cover-favicon-success.svg" />
        </picture>
        <div class="card-success__header">{{ $t("constructor.productAddedToList") }}</div>
        <aside class="card-success__content card-success__content--padding">
          {{ $t("constructor.prodductAddedToListText1") }}
          <a class="cursor-pointer link" @click.prevent="openOwnerList"
            >{{ $t("constructor.prodductAddedToListText2") }}</a
          >
        </aside>
        <div class="card-success__actions"></div>
      </section>
    </div>

  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import LoadingButton from "../components/LoadingButton.vue";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import axios from "axios";
import { mapGetters } from "vuex";
import bbButton from "./buttons/bbButton.vue";
import Features from "../Mixins/Features";

export default {
  components: {
    VueSlickCarousel,
    LoadingButton,
    bbButton,
  },
  name: "ProductAddedToList",
  data() {
    return {
      showPromotedProducts: false,
      slides: [],
      // slides example
      // {
      //   id: 1,
      //   name: "Sophie la girafe",
      //   price: 22,
      //   salePrice: 20,
      //   img: "https://images.hellobb.net/twist-and-click-desktop.png",
      //   mk: {
      //     active: true,
      //     label: "¡Oferta!",
      //     discount: "-15",
      //   },
      // },
      // {
      //   id: 1,
      //   name: "Pack de ropa",
      //   price: 22,
      //   salePrice: 20,
      //   img: "ropa.jpeg",
      //   mk: {
      //     active: true,
      //     label: "¡Oferta!",
      //     discount: "-15",
      //   },
      // },
      // {
      //   id: 2,
      //   name: "Sophie la girafe 2",
      //   price: 22,
      //   // salePrice: 20,
      //   img: "slide-otros-001.png",
      //   mk: {
      //     active: false,
      //     label: "¡Oferta!",
      //     discount: "-15",
      //   },
      // },
      currentIndex: 0,
      settingsCarousel: {
        slidesToShow: 1,
        centerMode: false,
        // infinite: true,
        autoplaySpeed: 10000,
        autoplay: false,
        dots: false,
        arrows: true,
        draggable: false,
        responsive: [
          {
            breakpoint: 767,
            settings: {
              arrows: true,
              slidesToShow: 1,
              draggable: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              autoplay: false,
              centerMode: true,
              centerPadding: "30px",
              arrows: false,
              slidesToShow: 1,
              draggable: true,
            },
          },
        ],
      },
      adding: false,
      selectedProduct: null,
    };
  },
  mixins: [
    Features,
  ],
  computed: {
    ...mapGetters({
      retailers: "retailers",
      isAuthenticated: "isAuthenticated",
      myList: "ownerList",
      contributionsFlag: "contributionsFlag",
    }),
    isMiLista() {
      return this.$route.name === "owner-list";
    },
    show() {
      return this.$route.name === "add-product"; //|| this.$route.name === "list-constructor";
    },
  },
  methods: {
    openOwnerList() {
      if (!this.$utils.isApp()) {
        this.$emit("close")
        this.$router.push({ name: "owner-list" });
      } else {
        location.href = process.env.APP_URL + "mi-lista?source=app";
      }
    },
    async addProduct(slide) {
      this.adding = true;
      try {
        let body = {};
        let product = {
          id: slide.id,
        };
        await this.$store.dispatch("addProductToList", {
          list: this.myList,
          product: product,
          body,
        });
        this.trackAdProduct(slide);
        await this.getList();
        this.adding = false;
      } catch (error) {
        console.log(error);
        this.adding = false;
        return;
      }
    },
    async getList() {
      try {
        await this.$store.dispatch("getOwnerList");
      } catch (error) {
        console.log(error);
      }
    },
    isInTheList(id) {
      //return true if the products in myList has a product with the same id
      return this.myList.products.some((product) => product.id === id);
    },
    goToProduct(id, slug) {
      //open new tab
      window.open("https://www.hellobb.net/catalogo/" + slug + "/" + id, "_blank");
    },
    trackAdProduct(slide) {
      window.mixpanel.track("Afegir objecte", {
        "Item Category": "TBD",
        "Item name": slide.name,
        //"Item Price": this.product.price,
        "Add type": "Objecte catàleg",
        "Add Platform": this.$getPlatform(),
        "Add client": this.$store.state.cookies.sourceApp == "true" ? "App" : "Webapp",
        Source: "Banner producte promocionat",
        "Num retailers": 1,
        "Item retailer": "HelloBB",
        "Item retailers": ["HelloBB"],
        "Sold by HelloBB": true,
        "Item URL": "https://www.hellobb.net/p/" + slide.id,
        "Product ID": slide.id,
      });
      window.mixpanel.people.increment("Total Add");
      window.mixpanel.people.increment("List # elements");
      window.mixpanel.people.increment("List value", slide.price);
      window.mixpanel.people.set({ "Last Add": new Date().toISOString() });
      window.mixpanel.people.set_once({ "First Add": new Date().toISOString() });
    },
  },
  async mounted() {
    if (this.show && this.featureEcommerceAvailable) {
      let response = await axios.get("/promotedproduct");
      //this.slides = response.data;
      this.slides = response.data.filter(function (el) {
        return el.bannerId == 2;
      });
      //get out items in slides that are already in the list
      this.slides = this.slides?.filter((slide) => !this.isInTheList(slide.id));
      //random sorting
      this.slides?.sort(() => Math.random() - 0.5);
      if (this.slides?.length > 0) {
        this.showPromotedProducts = true;
      }
      this.selectedProduct = this.slides[0];
    }
  },
};
</script>
