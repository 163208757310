export default {
  methods: {
    initializeSessionStorage(key, defaultValue) {
      const rawValue = window.sessionStorage.getItem(key);
      if (rawValue === null && typeof defaultValue !== "undefined") {
        return this.setSessionStorage(key, defaultValue);
      }

      try {
        const value = JSON.parse(rawValue);

        return value;
      } catch (err) {
        console.error(err);

        return null;
      }
    },
    setSessionStorage(key, value) {
      window.sessionStorage.setItem(key, JSON.stringify(value));

      return value;
    },
  },
}
