export default {
    methods: {
        async saveOnboardingStep1(stepData) {
          if (stepData.childBirthday) {
            try {
              await this.$store.dispatch("updateChildBirthday", stepData.childBirthday);
            } catch (error) {
              console.log(error);
            }
            this.$store.commit("updateUserField", {
              field: "childBirthday",
              value: stepData.childBirthday
            });
          }

          if (stepData.shouldUpdateBabyRegistryFlag) {
            await this.$store.dispatch("updateBabyRegistryFlag", false);
          }
        },
    },
}
