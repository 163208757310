<template>
  <div class="list-product__bba">
    <div class="bba-banner">
      <div class="bba-banner__header">
        <strong>Anuncio</strong>

        <span
          class="link link--underline"
          @click="upgradeToPremium()"
          v-if="!userIsVisitor"
        >
          Quitar publicidad
        </span>
      </div>

      <Promotion v-if="promotion" :promotion="promotion" />
      <div v-else>
        <Adsense :adType="adType" />
        <FallbackAd />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Adsense, { TYPE_IMAGE_SIDE } from "./Adsense.vue";
import FallbackAd from "./FallbackAd.vue";
import Promotion from "./Promotion.vue";

export default {
  name: "InFeedAd",
  components: {
    Adsense,
    FallbackAd,
    Promotion,
  },
  data() {
    return {
      adType: TYPE_IMAGE_SIDE,
      showFallback: false,
      promotionIndex: -1,
      promotion: null,
    };
  },
  computed: {
    ...mapGetters({
      userIsVisitor: "userIsVisitor",
      promotions: "promotions",
    }),
  },
  methods: {
    upgradeToPremium() {
      return this.$store.dispatch("upgradeToPremium");
    },
  },
  async beforeMount() {
    this.promotionIndex = await this.$store.dispatch("getNextPromotionIndex");
    this.promotion = this.promotions[this.promotionIndex];
  },
};
</script>
